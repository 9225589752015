import MuiAlert from '@mui/material/Alert'

export function isPdf(ecg) {
    if (ecg.attachment === {} || !ecg.attachment) {
        return false;
    }

    return ecg?.attachment?.content_type === 'application/pdf';
}

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
