import React from 'react'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  modal: { minWidth: 400 },
  bigText: {
    fontSize: 16,
    color: theme.palette.grey[900],
    marginBottom: theme.spacing(1),
    lineHeight: 1,
  },
  smallText: {
    fontSize: 12,
    marginBottom: 0,
  },
  dialogTitle: {
    borderBottom: '1px solid #E9EAEC;',
  },
}))

// import clsx from 'clsx'

function UniversalDialog({
  open = false,
  title,
  icon = {
    icon: null,
    color: '#fff',
  },
  bigText = null,
  smallText = null,
  buttons = [],
  children,
}) {
  const classes = useStyles()
  return (
    <Dialog open={open} aria-labelledby="universal-dialog">
      {title && (
        <DialogTitle className={classes.dialogTitle} id="universal-dialog">
          {title}
        </DialogTitle>
      )}
      <DialogContent className={classes.modal}>
        {children ? (
          children
        ) : (
          <Grid container alignItems="center">
            {icon.icon && (
              <Grid item>
                {React.createElement(icon.icon, {
                  style: {
                    fontSize: 60,
                    marginRight: 16,
                    color: icon.color,
                  },
                })}
              </Grid>
            )}
            <Grid item>
              {bigText && (
                <DialogContentText className={classes.bigText}>
                  {bigText}
                </DialogContentText>
              )}
              {smallText && (
                <DialogContentText className={classes.smallText}>
                  {smallText}
                </DialogContentText>
              )}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      {!!buttons.length && (
        <DialogActions>
          {buttons.map((button, index) => (
            <Button key={index} {...button.props}>
              {button.text}
            </Button>
          ))}
        </DialogActions>
      )}
    </Dialog>
  )
}

export default UniversalDialog
