import { createSlice } from '@reduxjs/toolkit'
import API from '../../common/api'
import * as endpoints from '../../common/endpoints'

export const auditSlice = createSlice({
  name: 'audit',
  initialState: {
    audits: [],
    rhythms: [],
    criticalRhythms: [],
    moderateRhythms: [],
    mildRhythms: [],
    normalRhythms: [],
  },
  reducers: {
    setAudits: (state, action) => void (state.audits = action.payload),
    appendAudits: (state, action) => {
      state.audits = [...state.audits, ...action.payload]
    },
    setAudit: (state, action) => void (state.audit = action.payload),
    setRhythms: (state, action) => void (state.rhythms = action.payload),
    setLoader: (state, action) => void (state.loading = action.payload),
    clearAudits: (state) => {
      state.audits = []
    },
  },
})

export const {
  appendAudits,
  setAudit,
  setAudits,
  setRhythms,
  clearAudits: clearAuditsReducer,
} = auditSlice.actions

export const loadAuditAsync = (cursor) => (dispatch) => {
  return API.get(endpoints.GET_AUDITS(cursor))
    .then((e) => {
      dispatch(appendAudits(e.data.data))
    })
    .catch((e) => alert(e))
}

export const loadRhythmAsync = () => (dispatch) => {
  API.get(endpoints.RHYTHMS).then((e) => {
    dispatch(setRhythms(e.data.data))
  })
}

export const clearAudits = () => (dispatch) => {
  dispatch(clearAuditsReducer())
}

export const selectAudits = (state) => state.audit.audits
export const selectRhythms = (state) => state.audit.rhythms
export const selectAudit = (state) => state.audit.audit

export default auditSlice.reducer
