import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

export const reviewWarningsSlice = createSlice({
  name: 'reviewWarnings',
  initialState: {
    reviewWarnings: [],
  },
  reducers: {
    setReviewWarnings: (state, action) => {
      const newState = action.payload.map((warning) => ({
        text: warning,
        id: uuidv4(),
      }))
      state.reviewWarnings = newState
    },
    removeReviewWarning: (state, action) => {
      const newState = state.filter((warning) => warning.id !== action.payload)
      state.reviewWarnings = newState
    },
  },
})

export const { setReviewWarnings, removeReviewWarning } =
  reviewWarningsSlice.actions

export const selectReviewWarnings = (state) =>
  state.reviewWarnings.reviewWarnings

export default reviewWarningsSlice.reducer
