// this is also view for audit
import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { auditGetScore } from '../../common/utils'
import {
  Grid,
  TextareaAutosize,
  Radio,
  Checkbox,
  FormControlLabel,
  Button,
  RadioGroup,
  CircularProgress,
  CardContent,
  Card,
  Typography,
  Divider,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LaunchIcon from '@mui/icons-material/Launch'

import Backdrop from '../../components/Backdrop'

import { loadRhythmAsync, selectRhythms } from './audit-slice'
import { useSelector, useDispatch } from 'react-redux'

import { useParams } from 'react-router-dom'
import API from '../../common/api'
import * as endpoints from '../../common/endpoints'

import { SESSION_COOKIE } from '../../common/constants'
import { setNotAuthenticated } from '../../features/login/loginSlice'
import { waitForDomChange } from '@testing-library/react';
import { submitStates } from '../../common/enums';

const useStyles = makeStyles((theme) => ({
  scoreWrapper: {
    display: 'inline-block',
  },
  score: {
    position: 'absolute',
    top: -5,
    right: 15,
    borderRadius: 5,
    padding: '4px 10px',
  },
  formTitles: {
    padding: '7px 0',
  },
}))

function ScoreBadge({ value }) {
  const classes = useStyles()
  const valueInt = parseInt(value)
  const colors = [
    {
      textHex: '#24A52A',
      backgroundHex: '#e9f6ea',
      from: 81,
      to: 100,
    },
    {
      textHex: '#FFD400',
      backgroundHex: '#fffbe5',
      from: 61,
      to: 80,
    },
    {
      textHex: '#EF6C00',
      backgroundHex: '#fdf0e4',
      from: 31,
      to: 60,
    },
    {
      textHex: '#DA100B',
      backgroundHex: '#fbe7e7',
      from: 0,
      to: 30,
    },
  ]
  const color = colors.find((color) => {
    return valueInt >= color.from && valueInt <= color.to
  })
  return (
    <Typography
      className={classes.score}
      style={{ color: color.textHex, backgroundColor: color.backgroundHex }}
    >
      {valueInt}%
    </Typography>
  )
}

export function AuditCreate({ forceSubmit = null,
  getadditionalData, handleChangeSubmitState
 }) {
  const classes = useStyles()
  const { id } = useParams()
  const rhythms = useSelector(selectRhythms)
  const dispatch = useDispatch()
  React.useEffect(() => dispatch(loadRhythmAsync()), [dispatch])
  return <AuditForm {...{ id, rhythms,getadditionalData, handleChangeSubmitState }} forceSubmit={forceSubmit} />
}


function RhythmMissed(props) {
  const { rhythms, rhythmCatMissed, setMissed, category, selected } = props

  if (!rhythmCatMissed.includes(category)) {
    return <> </>
  }

  return (
    <Grid item xs={12} container direction="column">
      <h2>What {category} rhythm was missed?</h2>
      {rhythms
        .filter((r) => r.category === category)
        .map((critical) => (
          <FormControlLabel
            key={critical.id}
            control={
              <Checkbox
                value={critical.id}
                color="primary"
                checked={selected.includes(critical.id)}
                onChange={(e) =>
                  e.target.checked
                    ? setMissed((oldState) => [...oldState, critical.id])
                    : setMissed((oldState) =>
                        oldState.filter((x) => x !== critical.id)
                      )
                }
              />
            }
            label={critical.name}
          />
        ))}
    </Grid>
  )
}

function AuditForm(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [cookies, setCookie, removeCookie] = useCookies()

  const [loader, setLoader] = useState(false)
  const [score, setScore] = useState(0)
  const [accurateMeasureMissed, setAccurateMeasureMissed] = useState(false)
  const [rhythmCatMissed, setRhythmCatMissed] = useState([])
  const [criticalMissed, setCriticalMissed] = useState([])
  const [moderateMissed, setModerateMissed] = useState([])
  const [mildMissed, setMildMissed] = useState([])
  const [normalMissed, setNormalMissed] = useState([])
  const [etopicMarkerMissed, setEtopicMarkerMissed] = useState('no')
  const [warmMessageInapp, setWarmMessageInapp] = useState('no')
  const [comment, setComment] = useState('')
  const [status, setStatus] = useState(0)
  const [forceSubmit, setForceSubmit] = useState(null)
  const [isBackdropOpen, setIsBackdropOpen] = useState(false)
  const {id, rhythms ,getadditionalData }  = props

  useEffect(() => {
    if (
      typeof props.forceSubmit === 'number' &&
      props.forceSubmit !== forceSubmit
    ) {
      setForceSubmit(props.forceSubmit)
      handleSubmit()
    }
  }, [props.forceSubmit])

  useEffect(() => {
    setIsBackdropOpen(true)
    API.get(endpoints.AUDIT(id))
      .then((e) => {
        setAccurateMeasureMissed(e.data.data.accurate_measurement_missed)
        setRhythmCatMissed(e.data.data.rhythm_category_missed || [])
        setCriticalMissed(e.data.data.critical_rhythm_missed || [])
        setModerateMissed(e.data.data.moderate_rhythm_missed || [])
        setMildMissed(e.data.data.mild_rhythm_missed || [])
        setNormalMissed(e.data.data.normal_rhythm_missed || [])
        setEtopicMarkerMissed(e.data.data.etopic_beat_markers_missed || 'no')
        setWarmMessageInapp(e.data.data.warm_message_inappropriate || 'no')
        setComment(e.data.data.comment || '')
        setScore(e.data.data.score)
        setStatus(e.data.data.status)
        setIsBackdropOpen(false)
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          API.get(endpoints.LOGOUT).finally((e) => {
            removeCookie(SESSION_COOKIE)
            dispatch(setNotAuthenticated())
          })
        }
        setIsBackdropOpen(false)
        if (e.response && e.response.status === 401) {
          alert('Not Authorized')
        } else if (e.response && e.response.status === 404) {
          console.log('audit has not been created')
        } else {
          alert(e)
        }
      })
  }, [id])

  const handleSubmit = (e) => {
    e && e.preventDefault()
    setLoader(true)

    props.handleChangeSubmitState(submitStates.IN_PROGRESS)
    const {
      analysis,
      annotations,
      measurements,
      activerhythms,
      PACCount,
      PVCCount,
      avg_heart_rate,
      conversion_valid,
    } = getadditionalData()

    API.post(endpoints.AUDIT_2(id), {
      pac_count: PACCount,
      pvc_count: PVCCount,
      avg_heart_rate: avg_heart_rate,
      comment: comment,
      accurate_measurement_missed: accurateMeasureMissed,
      rhythm_category_missed: rhythmCatMissed,
      critical_rhythm_missed: criticalMissed,
      moderate_rhythm_missed: moderateMissed,
      mild_rhythm_missed: mildMissed,
      normal_rhythm_missed: normalMissed,
      etopic_beat_markers_missed: etopicMarkerMissed,
      warm_message_inappropriate: warmMessageInapp,
      analysis,
      annotations,
      measurements,
      rhythms: activerhythms,
      conversion_valid: conversion_valid,
    })
      .then((res) => {
        setLoader(false)
        props.handleChangeSubmitState(submitStates.DONE)
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          API.get(endpoints.LOGOUT).finally((e) => {
            removeCookie(SESSION_COOKIE)
            dispatch(setNotAuthenticated())
          })
        }
        setLoader(false)
        if (e.response && e.response.status === 401) {
          alert('Not Authorized')
        } else {
          alert(e)
        }
      })
  }

  return (
    <form noValidate onSubmit={handleSubmit} className={classes.form}>
      <Backdrop open={isBackdropOpen} />
      <Grid container align="left" spacing={2}>
        <Grid item xs={12}>
          <ScoreBadge value={auditGetScore(score)} />
          <Typography variant="h6" className={classes.formTitles}>
            Accurate measurement missed?
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                value={accurateMeasureMissed}
                checked={accurateMeasureMissed}
                onChange={(e) => setAccurateMeasureMissed(e.target.checked)}
                color="primary"
              />
            }
            label="Yes, measurement was missed."
          />
        </Grid>
       

        <Grid item xs={12} container direction="column">
          <Typography variant="h6" className={classes.formTitles}>
            Were ectopic beat markers (V, A, J) missed?
          </Typography>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={etopicMarkerMissed}
            onChange={(e) => setEtopicMarkerMissed(e.target.value)}
          >
            <FormControlLabel
              control={<Radio value="yes" color="primary" />}
              label="Yes, reviewer missed most of beat markers"
            />
            <FormControlLabel
              control={<Radio value="partially" color="primary" />}
              label="Reviewer missed some of beat markers"
            />
            <FormControlLabel
              control={<Radio value="no" color="primary" />}
              label="No, reviewer didn’t miss any beat markers "
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.formTitles}>
            Is warm message inappropriate?
          </Typography>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={warmMessageInapp}
            onChange={(e) => setWarmMessageInapp(e.target.value)}
          >
            <FormControlLabel
              control={<Radio value="yes" color="primary" />}
              label="Yes, warm message is inappropriate"
            />
            <FormControlLabel
              control={<Radio value="partially" color="primary" />}
              label="Partially inappropriate"
            />
            <FormControlLabel
              control={<Radio value="no" color="primary" />}
              label="No, warm message is appropriate"
            />
          </RadioGroup>
        </Grid>
       {warmMessageInapp !== "no" && <Grid item xs={12}>
          <Typography variant="h6" className={classes.formTitles}>
          Add warm message feedback
          </Typography>
          <TextareaAutosize
          placeholder="notes"
            variant="outlined"
            minRows={8}
            style={{ width: '100%', padding:"20px" }}
            label="Your Comment"
            name="email"
            autoComplete="email"
            className={classes.textarea}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Grid>}
      </Grid> 
    </form>
  );
}
