import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import GetAppIcon from '@mui/icons-material/GetApp'
import AssignIcon from "../../assets/svgs/assign.svg"

const TableHeader = ({title, counter, getNewTaskHandler}) => {
    return (
        <Grid container>
        <Grid item xs={9} align="left" style={{alignSelf: "center"}} >
          <Typography variant="h4" style={{fontWeight: '500'}} >{title}</Typography>
        </Grid>
        <Grid item xs={3} align="right">
          <div style={{display:"flex", justifyContent:"flex-end", gap:"1rem",  alignItems:"center"}}>
            
        {counter && <h4 id="remaining-tasks">Remaining Tasks: {counter}</h4>}
         {getNewTaskHandler && <Button variant="contained" color="primary" id="get-tasks" onClick={getNewTaskHandler}>
            <img style={{ height:"32px", width:"32px"}} src={AssignIcon}/>
            Get Task
          </Button> }
          </div>
        </Grid>
      </Grid>
    )
}

export default TableHeader
