import React from 'react'
import { Link } from 'react-router-dom'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material'

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import LockIcon from '@mui/icons-material/Lock'

import { auditGetScore } from '../../common/utils'
import { TableTag } from '../TaskTable/TaskTable.styled'
import { AUDIT_STATUS, TASK_STATUS } from '../../common/constants'

function AuditTable(props) {
  const { setCursor, audits } = props

  function statusToText(status) {
    switch (status) {
      case 0:
        return (
          <Button variant="outlined" color="primary">
            PENDING
          </Button>
        )
      case 1:
        return (
          <Button variant="outlined" color="secondary">
            DONE
          </Button>
        )
      default:
        return 'UNKNOWN'
    }
  }

  function rowDataToAction(data) {
    switch (data.status) {
      case 0:
        return (
          <Link to={`/review/${data.ecg_id}?mode=audit`}>
            <ArrowRightAltIcon />
          </Link>
        )
      case 1:
        return (
          <Link to={`/review/${data.ecg_id}?mode=audit`}>
            <LockIcon />
          </Link>
        )
      default:
        return 'UNKNOWN'
    }
  }

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Task</TableCell>
              <TableCell>Analysis Requested At</TableCell>
              <TableCell>Accuracy (score)</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {audits.map((row, index) => {
              return (
                <TableRow key={row.ecg_id} hover={true}>
                  <TableCell component="th" scope="row">
                    <b>{row.id}</b>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <b>{new Date(row.analysis_requested_at).toLocaleString()}</b>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <b>{auditGetScore(row.score)}</b>
                  </TableCell>
                  <TableCell component="th" scope="row">
                  <TableTag
                    label={AUDIT_STATUS[row.status]}
                    color="primary"
                    variant="outlined"
                  />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {rowDataToAction(row)}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <br />
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            let cursor = 0
            if (audits.length) {
              const maxId = audits.reduce(
                (max, audits) =>
                  parseInt(audits.id, 10) > max ? parseInt(audits.id, 10) : max,
                parseInt(audits[0].id, 10)
              )
              cursor = maxId
            }
            setCursor(prev => cursor)
          }}
        >
          Load More
        </Button>
      </div>
    </div>
  )
}

export default AuditTable
