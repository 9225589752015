import React, { useState } from 'react'

import makeStyles from '@mui/styles/makeStyles';

import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    // styles copied from mui toolbar
    color: '#fff',
    padding: '4px 8px',
    fontSize: '0.625rem',
    maxWidth: 300,
    wordWrap: 'break-word',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 500,
    lineHeight: '1.4em',
    borderRadius: 4,
    backgroundColor: 'rgba(97, 97, 97, 0.9)',
    // end of copied styles
    transition: 'all .15s',
    transform: 'translate(-50%, -50%)',
  },
  tooltipHidden: {
    opacity: 0.3,
  },
}))

//this one is hack because is not using real mui tooltip - problem with tooltip trying be always on screen
function AbsoluteTooltip({ state, doNotRender = false, children }) {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  if (!state || doNotRender) {
    return null
  }

  function handleEvent(type) {
    switch (type) {
      case 'mouseEnter':
        setOpen(false)
        break
      case 'mouseLeave':
        setOpen(true)
        break
      default:
        break
    }
  }
  return (
    <div
      style={{ position: 'absolute', top: state.y + 60, left: state.x }}
      className={clsx(classes.tooltip, !open && classes.tooltipHidden)}
      onMouseEnter={() => handleEvent('mouseEnter')}
      onMouseLeave={() => handleEvent('mouseLeave')}
    >
      {children}
    </div>
  )
}

export default AbsoluteTooltip
