

import { Chip } from "@mui/material";
import { styled } from '@mui/system';



const getColor = (score) => {
    let color  = '#24A52A'
    if(score < 81 && score > 60) {
        color = '#B39400'
    }else if(score < 61 && score > 30){
        color = '#EF6C00'
    }else if(score < 31){
        color = '#DA100B'
    }
    return color
}
export const ScoreTag = styled(Chip)(({ score }) => ({
    borderRadius: "4px",
    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${getColor(score)} ` ,
    color: getColor(score),
    border: 'none',
    marginRight: "10px"

}))