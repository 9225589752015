import React, { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from '../../contexts/Auth.context'
import { selectLogin } from '../../features/login/loginSlice'
import Navigation from '../Navigation'

function ProtectedRoute({ component: Component, withMenu, allow, ...rest }) {
  const {authenticated, role, piiaAgreement} = useContext(AuthContext)
  const Nav = useMemo(() => <Navigation />,[role])
  const allowed = allow ? allow() : true
  return (
    <>
   { withMenu &&
    Nav   }
    <Route
      {...rest}
      render={(props) =>
        (authenticated && piiaAgreement && allowed)? <Component {...props} /> : <Redirect to="/" />
      }
    /></>
  )
}

export default ProtectedRoute
