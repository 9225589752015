import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import * as ENDPOINTS from '../../common/endpoints'
import API from '../../common/api'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Tooltip from '@mui/material/Tooltip'

import makeStyles from '@mui/styles/makeStyles';
import { Checkbox, Divider, FormControlLabel, FormGroup, useTheme } from '@mui/material'

import SectionWrapper from '../../components/SectionWrapper'
import Navigation from '../../components/Navigation'
import ColCard from '../../components/ColCard'
import EditProfileDialog from '../../components/EditProfileDialog'
import Backdrop from '../../components/Backdrop'

import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import SentimentSatisfiedRoundedIcon from '@mui/icons-material/SentimentSatisfiedRounded'
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

import clsx from 'clsx'
import moment from 'moment-timezone'
import { ScoreTag } from './Profile.styled'
import { getLabelColorByCategory } from '../../common/utils'
import { useSelector } from 'react-redux'
import { getRhythms, selectRhythms } from '../../store/slices/rhythms'
import { PieChartOutline } from '@mui/icons-material'

const useStylesUIT = makeStyles((theme) => ({
  wrapperMargin: {
    marginBottom: 2,
    marginTop: 10
  },
  bigText: {
    fontWeight: 'bold',
  },
}))

function UserInfoTexts(props) {
  const classes = useStylesUIT()
  return (
    <Grid
      container
      direction="column"
      className={clsx(!props.isLast && classes.wrapperMargin)}
    >
      <Grid item sx={{ marginTop: "20px" }}>
        <Typography variant="caption" sx={{ color: "rgba(1, 13, 61, 0.61)" }}>{props.topText}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" className={classes.bigText}>
          {props.bottomText}
        </Typography>
      </Grid>
    </Grid>
  )
}

const useStylesIBWT = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    width: 48,
    height: 48,
    backgroundColor: '#e2eaf5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
  },
  textWrapper: {
    marginLeft: theme.spacing(),
  },
}))

function IconBoxWithTexts(props) {
  const classes = useStylesIBWT()
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.iconWrapper}>{props.icon}</Box>
      <Box className={classes.textWrapper}>
        <Typography variant="h6">{props.topText}</Typography>
        <Typography variant="caption">{props.bottomText}</Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  rightSection: {
    //   marginBottom: theme.spacing() * 2,
  },
  feedbackWrapper: {
    maxHeight: 400,
    overflowY: 'scroll',
  },
  feedbackTop: {
    display: 'flex',
    alignItems: 'center',
    margin: `calc(${theme.spacing() * 3}px 0 ${theme.spacing()} * 2)`,
  },
  feedbackBottom: {
    paddingBottom: theme.spacing() * 2,
    borderBottom: '1px solid #E9EAEC',
  },
  feedbackChip: {
    borderRadius: 4,
    marginRight: theme.spacing(),
  },
  feedbackTextDisabled: {
    opacity: '.6',
  },
  loadWarmMessagesButton: {
    margin: `${theme.spacing()} 0`,
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipWrapper: {
    height: 18,
  },
  tooltip: {
    fontSize: 18,
    marginLeft: theme.spacing() / 2,
  },
  link: {
    textDecoration: 'none',
  },
}))

function Profile() {
  const classes = useStyles()
  const theme = useTheme()

  const [isEditProfileDialogOpen, setIsEditProfileDialogOpen] = useState(false)
  const [userInfo, setUserInfo] = useState({})
  const [userStats, setUserStats] = useState({})
  const [userWarmMessages, setUserWarmMessages] = useState([])
  const [isBackdropOpen, setIsBackdropOpen] = useState(false)
  const [allWarmMessagesLoaded, setAllWarmMessagesLoaded] = useState(false)

  const warmMessagesRef = useRef(0)
  const blockLoadWarmMessagesRef = useRef(false)
  const [filter, setFilter] = useState({
    positive: true,
    negative: true,
    mixed: true,
  })

  const handleFilter = (event) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.checked,
    });
  };
  const { positive, negative, mixed } = filter;

  const handleEditProfileDialogOpen = () => {
    setIsEditProfileDialogOpen(true)
  }

  const handleSave = (data) => {
    setIsBackdropOpen(true)
    API.put(ENDPOINTS.USERINFO, data)
      .then(() => {
        getUserInfo()
        setIsEditProfileDialogOpen(false)
        setIsBackdropOpen(false)
      })
      .catch((e) => {
        console.error(e)
        setIsEditProfileDialogOpen(false)
        setIsBackdropOpen(false)
      })
  }

  const getUserInfo = () => {
    return new Promise((resolve, reject) => {
      API.get(ENDPOINTS.USERINFO)
        .then((data) => {
          setUserInfo(data.data.data)
          resolve(data.data.data)
        })
        .catch((e) => {
          console.error(e)
          reject()
        })
    })
  }

  const getUserStats = () => {
    return new Promise((resolve, reject) => {
      API.get(ENDPOINTS.USERSTATS)
        .then((data) => {
          setUserStats(data.data.data)
          resolve(data.data.data)
        })
        .catch((e) => {
          console.error(e)
          reject()
        })
    })
  }

  const getUserWarmMessages = (cursor) => {
    blockLoadWarmMessagesRef.current = true
    return new Promise((resolve, reject) => {
      API.get(ENDPOINTS.GETUSERWARMMESSAGES(cursor))
        .then((data) => {
          const result = data?.data?.data
          if (!result) {
            setAllWarmMessagesLoaded(true)
            resolve(result)
            return
          }
          setUserWarmMessages((prevValue) => [...prevValue, ...result])
          if (result.length < 10) {
            setAllWarmMessagesLoaded(true)
            resolve(result)
            return
          }
          warmMessagesRef.current = result[result.length - 1].id
          blockLoadWarmMessagesRef.current = false
          resolve(result)
        })
        .catch((e) => {
          console.error(e)
          reject()
        })
    })
  }

  const header = (
    <Grid container>
      <Grid item xs={8} align="left">
        <Typography variant="h4">Your Profile</Typography>
      </Grid>
    </Grid>
  )

  const createChipStyle = (type) => {
    const styles = {}
    if (!type) {
      return styles
    }
    switch (type.toLowerCase()) {
      case 'no':
        styles.backgroundColor = '#e9f6ea'
        styles.color = '#24A52A'
        break
      case 'partially':
        styles.backgroundColor = '#fdf0e5'
        styles.color = '#EF6C00'
        break
      case 'yes':
        styles.backgroundColor = '#fbe7e7'
        styles.color = '#DA100B'
        break
      default:
        break
    }
    return styles
  }

  const transcrypteWarmMessageType = (type) => {
    if (!type) {
      return 'N/A'
    }
    switch (type.toLowerCase()) {
      case 'no':
        return 'POSITIVE'
      case 'partially':
        return 'MIXED'
      case 'yes':
        return 'NEGATIVE'
      default:
        return 'N/A'
    }
  }

  useEffect(() => {
    const promises = [
      getUserInfo(),
      getUserStats(),
      getUserWarmMessages(warmMessagesRef.current),
      getRhythms()
    ]
    setIsBackdropOpen(true)
    Promise.all(promises).finally(() => {
      setIsBackdropOpen(false)
    })
  }, [])

  const allRhythms = useSelector(selectRhythms)

  return <>
    <Backdrop open={isBackdropOpen} />
    <Navigation />
    <SectionWrapper header={header}>
      <Grid container>
        <Grid item xs={3} container direction="column">
          <Grid item>
            <ColCard
              collapseNoDivider
              renderHeader={{ withoutTitle: true }}
              autoHeight
              noTopPadding
            >
              <Grid container direction="column">
                <Grid item style={{ marginBottom: theme.spacing() / 2 }}>
                  <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                    {userInfo.username}
                  </Typography>
                </Grid>
                {userInfo.country && <Grid item>
                  <Typography variant="caption" sx={{ display: "flex", alignItems: "center" }}>
                    <FlagOutlinedIcon
                      style={{
                        fontSize: 16,
                        marginRight: 4,
                      }}
                    />
                    {userInfo.country}
                    {userInfo.city && `, ${userInfo.city}`}
                  </Typography>
                </Grid>}
                <Grid item style={{ marginTop: theme.spacing(), marginBottom:"20px" }}>
                  <Button
                    color="primary"
                    startIcon={<CreateOutlinedIcon />}
                    onClick={handleEditProfileDialogOpen}
                  >
                    Edit Profile
                  </Button>
                </Grid>
              </Grid>
              <Divider />
              <Grid container direction="column">
                {userInfo.medical_background && <Grid item>
                  <UserInfoTexts
                    topText="Medical background"
                    bottomText={userInfo.medical_background}
                  />
                </Grid>}
                {userInfo.phone && <Grid item>
                  <UserInfoTexts
                    topText="Mobile"
                    bottomText={userInfo.phone}
                  />
                </Grid>}
                <Grid item>
                  <UserInfoTexts
                    topText="Email"
                    bottomText={userInfo.email}
                    isLast
                  />
                </Grid>
              </Grid>
            </ColCard>
          </Grid>
        </Grid>
        <Grid item xs={9} container direction="column">
          <Grid item className={classes.rightSection} >
            <ColCard
              collapseNoDivider
              wrapperStyles={{height: "88px"}}
              headerNoPadding
              renderHeader={{ withoutTitle: true }}
            >
              <Grid container wrap="wrap" xs={{gap:"4px"}} >
                <Grid item xs >
                  <IconBoxWithTexts
                    icon={<TrendingUpIcon color="primary" />}
                    topText={
                      typeof userStats.review_count === 'number'
                        ? userStats.review_count
                        : ''
                    }
                    bottomText="REVIEWED ECGs"
                  />
                </Grid>
                <Grid item xs>
                  <IconBoxWithTexts
                    icon={<PieChartOutline color="primary" />}
                    topText={
                      typeof userStats.accuracy === 'number'
                        ? `${parseInt(userStats.accuracy)}%`
                        : ''
                    }
                    bottomText="ACCURACY"
                  />
                </Grid>
                <Grid item xs>
                  <IconBoxWithTexts
                    icon={<SentimentSatisfiedRoundedIcon color="primary" />}
                    topText={
                      typeof userStats.message_quality === 'number'
                        ? `${parseInt(userStats.message_quality)}%`
                        : ''
                    }
                    bottomText="MESSAGE QUALITY"
                  />
                </Grid>
                <Grid item xs>
                  <IconBoxWithTexts
                    icon={<ScheduleRoundedIcon color="primary" />}
                    topText={
                      typeof userStats.average_handling_time === 'number'
                        ? moment(
                          userStats.average_handling_time * 1000
                        ).format('HH:MM:SS')
                        : ''
                    }
                    bottomText="AVG. HANDLING TIME"
                  />
                </Grid>
              </Grid>
            </ColCard>
          </Grid>
          <Grid item className={classes.rightSection}>
            <ColCard
              collapseNoDivider
              autoHeight
              noChildrenPadding
              title={`Interpretation's errors`}
            >
              <TableContainer style={{ maxHeight: 480 }}>
                <Table aria-label="simple table" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Rhythms</TableCell>
                      <TableCell>
                        <Grid container alignItems="center">
                          <Grid item>Added</Grid>
                          <Grid item className={classes.tooltipWrapper}>
                            <Tooltip
                              className={classes.tooltip}
                              title={
                                <Typography align="center">
                                  “Added” are rhythm labels that were added by
                                  you in scans.
                                </Typography>
                              }
                              placement="top"
                            >
                              <HelpOutlineOutlinedIcon />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>

                      <TableCell>
                        <Grid container alignItems="center">
                          <Grid item>Missed</Grid>
                          <Grid item className={classes.tooltipWrapper}>
                            <Tooltip
                              className={classes.tooltip}
                              title={
                                <Typography align="center">
                                  Missed are rhythm labels that were missed by
                                  you in scans.
                                </Typography>
                              }
                              placement="top"
                            >
                              <HelpOutlineOutlinedIcon />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>


                      <TableCell>
                        <Grid container alignItems="center">
                          <Grid item>Incorrect</Grid>
                          <Grid item className={classes.tooltipWrapper}>
                            <Tooltip
                              className={classes.tooltip}
                              title={
                                <Typography align="center">
                                  "Incorrect" are rhythm labels that were flagged
                                  incorrect by the auditor.
                                </Typography>
                              }
                              placement="top"
                            >
                              <HelpOutlineOutlinedIcon />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>


                      <TableCell>Your success ratio </TableCell>


                      <TableCell>Avg. success ratio </TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!userStats.rhythm_errors?.length &&
                      userStats.rhythm_errors.map((error) => (
                        <TableRow key={error.id}>
                          <TableCell component="th" scope="row" >
                            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                              <FiberManualRecordIcon
                                style={{
                                  color: getLabelColorByCategory(
                                    allRhythms.find((r) => r.id === error.id)
                                      ?.category
                                  ),
                                }}
                              />  {error.name}
                            </Box>

                          </TableCell>
                          <TableCell>{error.added}</TableCell>
                          <TableCell>{error.missed}</TableCell>
                          <TableCell>{error.incorrect}</TableCell>
                          <TableCell><ScoreTag score={error.success_ratio} label={`${error.success_ratio}%`} /></TableCell>
                          <TableCell><ScoreTag score={error.average_success_ratio} label={`${error.average_success_ratio}%`} /></TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </ColCard>
          </Grid>
          <Grid item className={classes.rightSection}>
            <ColCard
              collapseNoDivider
              noChildrenVerticalPadding
              noChildrenPadding
              autoHeight
              title="Warm message feedback"
              renderHeader={{
                withoutTitle: false,
                render: () => (
                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    style={{ width: 'auto' }}
                  >
                    <Grid item sx={{display:"flex", alignItems:"center", gap:"10px"}}>
                      <Typography sx={{fontWeight:"bold"}}>Type: </Typography>
                      <FormGroup sx={{flexDirection:"row"}}>
                        <FormControlLabel sx={{color:"gray"}} control={<Checkbox size={'small'}onChange={handleFilter}  name="positive"checked={positive} />} label="Positive" />
                        <FormControlLabel sx={{color:"gray"}} control={<Checkbox size={'small'}onChange={handleFilter}name="mixed" checked={mixed} />} label="Mixed" />
                        <FormControlLabel sx={{color:"gray"}} control={<Checkbox size={'small'} onChange={handleFilter}name="negative" checked={negative} />} label="Negative" />
                      </FormGroup>
                    </Grid>
                  </Grid>)
              }}
            >
              <Box >
                {userWarmMessages.filter(warmMessage => [positive && 'no', negative&& 'yes', mixed && 'partially'].includes(warmMessage.inappropriate)).map((warmMessage, i) => (
                  <Box  key={warmMessage.id} >
               
                    <Box className={classes.feedbackTop} sx={{margin:"20px 0 5px 20px"}}>
                      <Chip
                        className={clsx(classes.feedbackChip)}
                        style={createChipStyle(warmMessage.inappropriate)}
                        size="small"
                        label={transcrypteWarmMessageType(
                          warmMessage.inappropriate
                        )}
                      />
                      <Typography display="inline" style={{ lineHeight: 1, fontSize:"12px", color:"gray" }}>
                        {new Date(warmMessage.created_at).toLocaleString()}
                      </Typography>
                    </Box>
                    <Box className={classes.feedbackBottom} >
                      <Typography
                      sx={{marginLeft:"20px"}}
                        className={clsx(
                          !warmMessage.message && classes.feedbackTextDisabled
                        )}
                      >
                        {warmMessage.message}
                      </Typography>
                      {warmMessage.ecg_id && (
                        <Link
                          to={`/review/${warmMessage.ecg_id}?mode=live`}
                          className={classes.link}
                        >
                          <Typography color="primary" 
                          sx={{margin:" 0 0 20px 20px "}}>
                            Open full ECG...
                          </Typography>
                        </Link>
                      )}
                    </Box>
             
                  </Box>
                ))}
                {!allWarmMessagesLoaded && (
                  <Box>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button
                          color="primary"
                          className={classes.loadWarmMessagesButton}
                          onClick={() => {
                            if (blockLoadWarmMessagesRef.current) {
                              return
                            }
                            getUserWarmMessages(warmMessagesRef.current)
                          }}
                        >
                          Load more
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            </ColCard>
          </Grid>
          <Grid item className={classes.rightSection}>
            <ColCard
              collapseNoDivider
              autoHeight
              noChildrenPadding
              title={`Marker's error`}
            >
              <TableContainer style={{ maxHeight: 480 }}>
                <Table aria-label="simple table" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Markers</TableCell>
                      <TableCell>Added</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!userStats.markers?.length &&
                      userStats.markers.map((marker) => (
                        <TableRow key={marker.added}>
                          <TableCell component="th" scope="row">
                            {marker.label}
                          </TableCell>
                          <TableCell>{marker.added}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </ColCard>
          </Grid>
        </Grid>
      </Grid>
    </SectionWrapper>
    <EditProfileDialog
      open={isEditProfileDialogOpen}
      onClose={() => setIsEditProfileDialogOpen(false)}
      onSave={handleSave}
      userInfo={userInfo}
    />
  </>;
}

export default Profile
