import React, { useEffect, useMemo, useState } from 'react'

import {
    XYChart,
    Tooltip,
    AnimatedAreaSeries,
    Grid,
    buildChartTheme
} from "@visx/xychart";
import {curveCardinal } from '@visx/curve';

import {TestRecord} from "../../../testdata"
import { getEcgChartData } from '../../../services/tasks';
import { useParams } from 'react-router';
import BrushChart from './Brush';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

const accessors = {
    xAccessor: (d) => d.x * 100,
    yAccessor: (d) => d.y,
  };

  
const NewChart = () => {
    const [chartData, setChartData] = useState([])
    const {id} = useParams()
    useEffect(() => {
        if(id){

        getEcgChartData(id)
        }
    }, [id])
      
      const customTheme = buildChartTheme({
        // colors
        colors: "#DA100B", // categorical colors, mapped to series via `dataKey`s
        gridColor: "#B2BEC3",
      });

    return (
        <>
          <ParentSize>{({ width, height }) => <BrushChart width={width} height={400} />}</ParentSize>,

        {/* <XYChart height={200} 
        margin= {{ top: 10, right: 10, bottom: 10, left: 10 }} theme={customTheme} xScale={{ type: "band" }} yScale={{ type: "linear" }}  color="red"
        >
            <Grid columns rows  numTicks={10}  />

            <AnimatedAreaSeries  dataKey="Line 1" data={TestRecord.slice(0,1000)} {...accessors} fillOpacity={0} 
                curve={curveCardinal}/>
            <Tooltip
                snapTooltipToDatumX
                snapTooltipToDatumY
                showVerticalCrosshair
                showSeriesGlyphs
                renderTooltip={({ tooltipData, colorScale }) => <div></div>}
            />
        </XYChart>

        <XYChart height={200} 
        margin= {{ top: 10, right: 10, bottom: 10, left: 10 }} theme={customTheme} xScale={{ type: "band" }} yScale={{ type: "linear" }}  color="red"
        >
            <Grid columns rows  numTicks={10}  />

            <AnimatedAreaSeries  dataKey="Line 1" data={TestRecord.slice(1000,2000)} {...accessors} fillOpacity={0} 
                curve={curveCardinal}/>
            <Tooltip
                snapTooltipToDatumX
                snapTooltipToDatumY
                showVerticalCrosshair
                showSeriesGlyphs
                renderTooltip={({ tooltipData, colorScale }) => <div></div>}
            />
        </XYChart> */}
    </>
    )
}

export default NewChart
