import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { AppBar, Typography, Button, Menu, MenuItem, Tabs, Tab, Grid, Avatar, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Backdrop from '../Backdrop'
import Logo from '../Logo'
import { checkIfUserAdmin, checkIfUserCanAccessAudit, checkIfUserCanAccessLabeling, stringAvatar } from '../../common/utils'
import { AuthContext } from '../../contexts/Auth.context';
import { TasksContext } from '../../contexts/Tasks.context';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 100,
  },
  appBar: {
    boxShadow: '0 0 5px rgba(0, 0, 0, .3)',
    backgroundColor: '#fff',
    paddingLeft: "25px",
    paddingRight: "25px"
  },
  toolBar: {
    // minHeight: 60,
  },
  tabs: {
    height: '100%',
  },
  tab: {
    margin:"5px",
    height: '100%',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: { display: 'flex' },
  hide: {
    display: 'none',
  },
  content: {
    flexGrow: 1,
    marginLeft: 0,
  },
  accountIcon: {
    marginRight: theme.spacing(),
  },
}))

function Navigation(props) {

  const mainMenu = [
    {
      label: 'LIVE',
      redirectTo: '/live',
      availableForUser: true,
    },
    {
      label: 'LABELING',
      redirectTo: '/labeling',
      availableForUser: checkIfUserCanAccessLabeling(),
    },
    {
      label: 'PRACTICE',
      redirectTo: '/practice',
      availableForUser: checkIfUserCanAccessLabeling(),
    },
    {
      label: 'Audit',
      redirectTo: '/audit',
      availableForUser: checkIfUserCanAccessAudit(),
    },
    {
      label: <div style={{display:"flex", alignItems:"center", gap:"10px"}}><AdminPanelSettingsIcon/>ADMIN TOOLS</div>,
      redirectTo: '/tools',
      availableForUser: checkIfUserAdmin(),
    },
  ]

  const classes = useStyles()
  const { username, loading : authLoading, logout, role } = useContext(AuthContext)
  const { loading } = useContext(TasksContext)

  const [menuItems, setMenuItems] = React.useState(mainMenu)
  const [menuTabActive, setMenuTabActive] = React.useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const isMenuOpen = Boolean(anchorEl)

  const [isBackdropOpen, setIsBackdropOpen] = useState(false)

  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    handleChangeActiveMenuItem()
  }, [])

  useEffect(() => {
    handleChangeActiveMenuItem()
  }, [location])

  const handleChangeActiveMenuItem = () => {
    if (!props.renderMenu) {
      const index = mainMenu.findIndex(
        (item) => item.redirectTo === location.pathname
      )
      setMenuItems(menuItems)
      setMenuTabActive(index)
    }
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    handleMenuClose()
    logout()
  }

  const handleGotoProfileSettings = () => {
    history.push('/profile')
  }

  const handleLogoClick = () => {
    history.push('/')
  }

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id="primary-account-menu"
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleGotoProfileSettings}><AccountCircleOutlinedIcon style={{marginRight:"10px"}} />Profile</MenuItem>
      <MenuItem onClick={handleLogout}><ExitToAppIcon style={{marginRight:"10px"}} />Logout</MenuItem>
    </Menu>
  )

  const renderTabs =  (menuItems) => {
    return menuItems.map((item) =>
      item.availableForUser ? (
        <Tab
          sx={{height:"75px", fontSize:"15px", lineHeight:"28px"}}
          label={item.label}
          onClick={() => {
            history.push(item.redirectTo)
          }}
          key={item.label}
        />
      ) : null
    )
  }

  return (
    <div className={classes.root}>
      
      <Backdrop open={isBackdropOpen} />
      <AppBar color="inherit" position="static" className={classes.appBar} sx={{borderBottom: ".5px solid #E5E5E5"}}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Logo size={40} margin={'0 40px 0 0'} onClick={handleLogoClick} />
            </Grid>
            <Grid item className={classes.grow}>
              {props.renderMenu ? (
                props.renderMenu()
              ) : (
               <Tabs
                  className={classes.tabs}
                  value={menuTabActive}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="menu tabs"
                  TabIndicatorProps={{
                    sx:{
                      borderTopRightRadius:"10px",
                      borderTopLeftRadius:"10px",
                      height:"4px"
                    }
                  }}
                  scrollButtons="auto"
                >
                  {menuItems && renderTabs(menuItems)}
                </Tabs>
              )}
            </Grid>
            <Grid item>
              <div className={classes.sectionDesktop}>
                <Button onClick={() => history.push('/tutorial')}>Tutorial</Button>
                <Button
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="primary-account-menu"
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  {username && <Avatar {...stringAvatar(username)}  />}
                  <Typography>{username}</Typography>
                </Button>
              </div>
            </Grid>
          </Grid>
      </AppBar>
      {renderMenu}
      {(loading || authLoading) && <LinearProgress color={ loading? "inherit" : "error" } style={{opacity:".3"}} />}
    </div>
  );
}

export default Navigation
