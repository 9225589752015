import React, { useEffect, createContext, useState, useContext } from 'react'

import { getActiveTasksService, getRemainingActiveTasks, assignTaskService, getRemainingLabelingTasks, getLabelingTasksService, assignLabelingTaskService, getRemainingPracticeTasks, getPracticeTasksService, assignPracticeTaskService, getTaskService } from '../services/tasks'
import { AuthContext } from './Auth.context'

export const TaskContext = createContext(null)

const TaskProvider = ({ children }) => {
    const [task, setTask] = useState(null)
    const [loading, setLoading] = useState(false)
    const { logout } = useContext(AuthContext)

    const getTask = (id) => {
        setLoading(true)
        getTaskService(id).then(setTask).catch(logout).finally(() => setLoading(false))
    }



    return (
        <TaskContext.Provider value={{loading, getTask, task }}>
            {children}
        </TaskContext.Provider>
    )
}

export default TaskProvider
