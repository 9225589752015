
import { Chip } from "@mui/material";
import { styled } from '@mui/system';

export const ReviewTag = styled(Chip)(({ theme, mode }) => ({
    borderRadius: "4px",
    background: mode === 'labeling' ?  ' linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #EF6C00;' : "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #24A52A;",
    color:  mode === 'labeling' ? '#EF6C00' : '#24A52A',
    border: 'none',
    marginRight: "10px"

}))