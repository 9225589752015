import { Backdrop, Box } from "@mui/material";
import { keyframes } from "@emotion/react";
import AspectRatioIcon from '@mui/icons-material/AspectRatio'
import styled from "@emotion/styled";

const iconColorChange = keyframes`
   from: {
      color: '#fff',
    },
    to: {
      color: '#ff8181',
    },
`;
export  const WrapperBox = styled(Box)`
min-height: '100vh';

`
export const  BackdropWrapper = styled(Backdrop)`
    z-index: 10;
    color: #fff;
    background-color: rgba(0,0,0,.85);
    flex-direction: column;
    padding: 20px;
    animation: ${iconColorChange} 3s linear 0s infinite alternate;
`

export const  AspectRatioIconWrapper = styled(AspectRatioIcon)`
  
  font-size: 100;
    margin-bottom: 20px;
`