import React, { useState } from 'react'

import { Box, Typography, Grid, Card, CardContent } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'

import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 'inherit',
    boxShadow: '0px 1px 4px 0px #00000014',
    margin: theme.spacing(),
    display: 'flex',
    zIndex: '999',
    flexDirection: 'column',
  },
  noMargin: {
    margin: '0 !important',
  },
  fullHeight: {
    height: '100%',
  },
  fullHeightImportatnt: {
    // 16 px is theme spacing * 2
    height: 'calc(100% - 16px) !important',
  },
  header: {
    padding: theme.spacing(),
    paddingLeft: 16,
  },
  headerNoTitle: {
    borderBottom: 'none !important',
  },
  noPadding: {
    padding: `0 !important`,
  },

  headerBorder: {
    borderBottom: '1px solid rgb(0, 0, 0, 0.12)',
  },
  open: {},
  closed: {
    padding: 0,
  },
  closedVertical: {
    maxHeight: 0,
    padding: '0 !important',
  },
  hideContent: {
    display: 'none',
    opacity: 0,
    pointerEvents: 'none',
    width: 0,
  },
  hideTitle: {
    transform: 'rotate(90deg)',
    display: 'flex',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    transformOrigin: 'center 45%',
  },
  gridItemHeader: {
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    cursor: 'pointer',
    marginRight: 10,
  },
  iconHorizontal: {
    transform: 'rotate(-90deg)',
  },
  iconHorizontalRotated: {
    transform: 'rotate(90deg)',
  },
  lineLeft: {
    borderLeft: '2px solid #0F56B3',
  },
  lineLeftTransparent: {
    borderLeft: '2px solid transparent',
  },
  noRadius: {
    borderRadius: '0 !important',
  },
  contentExpanded: {
    borderTop: '1px solid rgb(0, 0, 0, 0.05)',
  },
  childrenWrapper: {
    maxWidth: '100%',
  },
  cardContent: { flex: 1 },
  cardContentNoPadding: {
    padding: '0 !important',
  },
  cardContentNoBottomPadding: {
    paddingBottom: '0px !important',
  },
  cardContentNoTopPadding: {
    paddingTop: '0px !important',
  },
  cardContentNoHorizontalPadding: {
    paddingRight: '0px !important',
    paddingLeft: '0px !important',
  },
  cardContentNoVerticalPadding: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
}))

function ColCard({
  children,
  collapsable,
  title,
  maxWidth,
  minWidth,
  onResize,
  className,
  autoHeight = false,
  noChildrenPadding = false,
  noBottomPadding = false,
  noTopPadding = false,
  noChildrenHorizontalPadding = false,
  noChildrenVerticalPadding = false,
  // fields: withoutTitle => if title still visible, render => function render
  renderHeader = {},
  hasBorder = true,
  horizontalCollapse = false,
  collapseNoDivider = false,
  withLine = false,
  noWrapperMargin = false,
  noWrapperRadius = false,
  wrapperStyles = {},
  headerStyles = {},
  contentStyles = {},
  defaultExpanded = true,
  headerNoPadding = false,
}) {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded)

  const classes = useStyles()

  const handleCollapse = () => {
    setIsExpanded(!isExpanded)
    if (onResize) {
      onResize(!isExpanded)
    }
  }

  return (
    <Card
      style={{ maxWidth }}
      style={wrapperStyles}
      className={clsx(
        classes.wrapper,
        noWrapperMargin && classes.noMargin,
        noWrapperRadius && classes.noRadius,
        !autoHeight && classes.fullHeight,
        isExpanded ? classes.open : clsx(classes.closed),
        className && className,
        horizontalCollapse && !isExpanded && classes.fullHeightImportatnt
      )}
    >
      <Box
        className={clsx(
          classes.header,
          hasBorder && classes.headerBorder,
          renderHeader.withoutTitle && classes.headerNoTitle,
          headerNoPadding && classes.noPadding
        )}
        style={headerStyles}
      >
        <Grid
          container
          alignItems="center"
          className={clsx(
            horizontalCollapse && !isExpanded && classes.hideTitle
          )}
          justifyContent={renderHeader.render ? 'space-between' : 'flex-start'}
          direction={
            collapsable ? (horizontalCollapse ? 'row' : 'row-reverse') : ''
          }
        >
          {collapsable && (
            <Grid item className={classes.gridItemHeader}>
              <DoubleArrowIcon
                className={clsx(
                  classes.icon,
                  !horizontalCollapse &&
                    (isExpanded
                      ? classes.iconHorizontal
                      : classes.iconHorizontalRotated)
                )}
                onClick={handleCollapse}
              />
            </Grid>
          )}
          {!renderHeader.withoutTitle && (
            <Grid item className={classes.gridItemHeader}>
              <Typography sx={{fontSize:"16px", fontWeight:500 , lineHeight: "150%"}}>{title}</Typography>
            </Grid>
          )}
          {renderHeader.render && renderHeader.render()}
        </Grid>
      </Box>
      <CardContent
        style={contentStyles}
        sx={{zIndex:0}}
        className={clsx(
          classes.cardContent,
          noChildrenPadding && classes.cardContentNoPadding,
          noBottomPadding && classes.cardContentNoBottomPadding,
          noTopPadding && classes.cardContentNoTopPadding,
          noChildrenHorizontalPadding && classes.cardContentNoHorizontalPadding,
          noChildrenVerticalPadding && classes.cardContentNoVerticalPadding,
          !isExpanded &&
            clsx(classes.closed, !horizontalCollapse && classes.closedVertical)
        )}
      >
        <Grid
          container
          direction="column"
          style={{ position: 'relative' }}
          className={clsx(
            isExpanded && !collapseNoDivider && classes.contentExpanded,
            classes.childrenWrapper,
            !isExpanded && classes.hideContent
          )}
        >
          {children}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ColCard
