import React, {  useContext, useEffect, useState } from 'react'

import {
  Grid,
  Card,
  CardContent,
  CssBaseline,
} from '@mui/material';
import Navigation from '../../components/Navigation'
import SectionWrapper from '../../components/SectionWrapper'


import TaskTable from '../../components/TaskTable';
import TableHeader from '../../components/TableHeader';
import { TasksContext } from '../../contexts/Tasks.context';
import Tutorial from '../../components/Tutorial';
import {useTour} from "@reactour/tour"

const TutorialPage = () => {
    const [taskData, setTaskData] = useState([])  
    const { setIsOpen, setCurrentStep, setDisabledActions } = useTour()

    const getData= () =>{
      setDisabledActions(false)
      setCurrentStep(prev => prev +1)
      setTaskData([{
        ecg_id: "Tutorial task",
        status: 2,
      }])
    }

    useEffect(() => {
      setIsOpen(true)
      return () => {
        setCurrentStep(0)
        setIsOpen(false)
      }
    }, [])
    return (
        <>
      <CssBaseline />
      <SectionWrapper header={<TableHeader counter={12} title="Tutorial" getNewTaskHandler={getData} />}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} align="center">
            <Card>
              <CardContent sx={{padding:"0"}}>
                <TaskTable {...{ taskData, mode: 'tutorial' }} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </SectionWrapper>
    </>
    )
}

export default TutorialPage
