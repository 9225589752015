import { createSlice } from '@reduxjs/toolkit'
import * as endpoints from '../../common/endpoints'
import API from '../../common/api'

export const userHistorySlice = createSlice({
  name: 'userHistory',
  initialState: {
    userHistory: [],
  },
  reducers: {
    setUserHistory: (state, action) => {
      state.userHistory = Array.from(action.payload)
    },
    clearUserHistory: (state) => {
      state.userHistory = []
    },
  },
})

export const { setUserHistory, clearUserHistory } = userHistorySlice.actions

export const selectUserHistory = (state) => state.userHistory.userHistory

export const getUserHistory = async (id, cursor) => {
  const store = await import('../store')
  return new Promise((resolve, reject) => {
    API.get(endpoints.GET_USER_HISTORY(id, cursor))
      .then((data) => {
        data = data.data.data
        store.default.dispatch(setUserHistory(data))
        resolve(data)
      })
      .catch((e) => {
        console.error(e)
        reject(e)
      })
  })
}

export default userHistorySlice.reducer
