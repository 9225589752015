import { Box } from '@mui/material'
import React, { useRef, useState } from 'react'
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Empty from "../../assets/svgs/edit.svg"
import Card from './components/Card';
import { useForm } from "react-hook-form";
import InterpretationBar from '../InterpretationBar';
import {
  sanityErrors as sanityErrorsEnum,
} from '../../common/enums';
import PQRSTFields from './components/PQRSTFields';
import Chart from './components/Chart';
import NewChart from './components/NewChart';



const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const EmptyDiv = styled('div')(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "50px"
}));

const Viewer = ({ mode, task }) => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = data => console.log(data);

  const [analysisText, setAnalysisText] = useState('')
  const [activeRecordLabel, setActiveRecordLabel] = React.useState(task.rhythms || [])
  const [snackbar, setSnackbar] = useState(null)

  const [sanityErrors, setSanityErrors] = useState([
    sanityErrorsEnum.NO_RHYTHM_SELECTED,
    sanityErrorsEnum.EMPTY_PQRST,
    sanityErrorsEnum.NO_FULL_SCROLL,
  ])
  const warmMessageRef = useRef('')



  const onRhythmsChange = () => {
    console.log("error hadle")
  }

  const validateSanityError = (isError, sanityErrorName) => {
    const isErrorInState = sanityErrors.includes(sanityErrorName)
    if (isError && !isErrorInState) {
      setSanityErrors((prevState) => [...prevState, sanityErrorName])
    }
    if (!isError && isErrorInState) {
      setSanityErrors((prevState) =>
        prevState.filter((error) => error !== sanityErrorName)
      )
    }
  }

  const notes = null
  return (
    <Box sx={{ padding: "20px", backgroundColor: "#F7F8FA" }} >
      <Grid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Card title="Note" 
          content={notes || <EmptyDiv><img src={Empty} style={{ marginRight: "10px" }} alt="note" /> 
              No note to display
          </EmptyDiv>}
          />
        </Grid>
        <Grid item xs={6}>
          <Card title="PQRST" content={
            <form onSubmit={handleSubmit(onSubmit)}>
              <PQRSTFields register={register} />
            </form>
          } />
        </Grid>
        <Grid item xs={12}>
          <Card title="ECG Strip" content={<NewChart />} />
        </Grid>
      </Grid>

      <InterpretationBar
        handleSubmit={handleSubmit}
        analysisText={analysisText}
        warmMessageRef={warmMessageRef}
        id={''}
        mode={mode}
        activeRecordLabel={activeRecordLabel}
        sanityErrors={sanityErrors}
        onRhythmsChange={onRhythmsChange}
        setSnackbarECGView={setSnackbar}
        validateSanityError={validateSanityError}
      />
    </Box>
  )
}

export default Viewer
