import { configureStore } from '@reduxjs/toolkit'
// TODO: move all slices to /slices
import counterReducer from '../features/counter/counterSlice'
import loginReducer from '../features/login/loginSlice'
import taskReducer from '../features/dashboard/tasksSlice'
import auditReducer from '../features/audit/audit-slice'
import reviewErrorsReducer from './slices/reviewErrors'
import reviewWarningsReducer from './slices/reviewWarnings'
import ecgReducer from './slices/ecg'
import userHistoryReducer from './slices/userHistory'
import rhythmsReducer from './slices/rhythms'
import labellingTaskReducer from "../features/labelling/labellingTasksSlice";

export default configureStore({
  reducer: {
    counter: counterReducer,
    login: loginReducer,
    task: taskReducer,
    labellingTask: labellingTaskReducer,
    audit: auditReducer,
    reviewErrors: reviewErrorsReducer,
    reviewWarnings: reviewWarningsReducer,
    ecg: ecgReducer,
    userHistory: userHistoryReducer,
    rhythms: rhythmsReducer,
  },
})
