import { createSlice } from '@reduxjs/toolkit'
import * as endpoints from '../../common/endpoints'
import API from '../../common/api'

export const rhythmsSlice = createSlice({
  name: 'rhythms',
  initialState: {
    rhythms: [],
  },
  reducers: {
    setRhythms: (state, action) => {
      state.rhythms = Array.from(action.payload)
    },
    clearRhythms: (state) => {
      state.rhythms = []
    },
  },
})

export const { setRhythms, clearRhythms } = rhythmsSlice.actions

export const selectRhythms = (state) => state.rhythms.rhythms

export const getRhythms = async () => {
  const store = await import('../store')
  return new Promise((resolve, reject) => {
    API.get(endpoints.RHYTHMS)
      .then((data) => {
        data = data.data.data
        store.default.dispatch(setRhythms(data))
        resolve(data)
      })
      .catch((e) => {
        console.error(e)
        reject(e)
      })
  })
}

export default rhythmsSlice.reducer
