import API from './api'
import * as endpoints from './endpoints'
import { eventTypes } from './enums'

export const sendEvent = (id, type) => {
  if (!eventTypes[type] || window.location.hostname === 'localhost') {
    return
  }
  API.post(endpoints.SENDEVENT(id), { type })
}
