import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'

import {
  Dialog,
  DialogContent,
  DialogContentText,
  CircularProgress,
  IconButton,
  DialogActions,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { red } from '@mui/material/colors'
import CloseIcon from '@mui/icons-material/Close'
import MoodIcon from '@mui/icons-material/Mood'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import { useHistory } from 'react-router-dom'

import API from '../../common/api'
import { submitStates } from '../../common/enums'
import { MakeRedirect } from '../../common/utils'
import { SESSION_COOKIE } from '../../common/constants'
import { setNotAuthenticated } from '../../features/login/loginSlice'
import { selectReviewErrors } from '../../store/slices/reviewErrors'
import { selectReviewWarnings } from '../../store/slices/reviewWarnings'
import {LABELLING_ASSIGN, LABELLING_ASSIGNED, LABELLING_AVAILABLE_COUNT} from "../../common/endpoints";

const endpoints = require('../../common/endpoints')

export default function LoaderDialog(props) {
  const {
    onClose,
    submitState: sumbitStateProps,
    open,
    setForceSubmit,
    isAudit,
    isLabelling,
    onFixErrorsClick,
    handleSubmitNoValidation,
  } = props

  const history = useHistory()
  const [cookies, setCookie, removeCookie] = useCookies()
  const dispatch = useDispatch()
  const reviewErrors = useSelector(selectReviewErrors)
  const reviewWarnings = useSelector(selectReviewWarnings)

  const [submitState, setSubmitState] = useState(submitStates.IN_PROGRESS)

  const handleSaveDone = async () => {
    const auditQueryParam = `?mode=${isAudit ? 'audit' : 'live'}`;
    const labelQueryParam = `?mode=${isLabelling ? 'labeling' : 'false'}`;
    if (isAudit) {
      try {
        const audits = await API.get(endpoints.GET_AUDITS(0))

        if (audits.data.data.length) {
          history.push(
            `/review/${audits.data.data[0].ecg_id}${auditQueryParam}`
          )
          setTimeout(() => {
            window.location.reload(false)
          }, 100)
          return
        }

        setSubmitState(submitStates.DONE)
        return
      } catch (e) {
        if (e.response?.status === 401) {
          API.get(endpoints.LOGOUT).finally((e) => {
            removeCookie(SESSION_COOKIE)
            dispatch(setNotAuthenticated())
          })
        }
      }
    } else if (isLabelling){
      try {
        const assignedTasks = await API.get(endpoints.LABELLING_ASSIGNED)
        if (assignedTasks.data.data.length) {
          history.push(
              `/review/${assignedTasks.data.data[0].ecg_id}${labelQueryParam}`
          )
          setTimeout(() => {
            window.location.reload(false)
          }, 100)
          onClose()
          return
        }
      } catch (e) {
        if (e.response?.status === 401) {
          API.get(endpoints.LOGOUT).finally((e) => {
            removeCookie(SESSION_COOKIE)
            dispatch(setNotAuthenticated())
          })
        }
      }

      try {
        const availableTasks = await API.get(endpoints.LABELLING_AVAILABLE_COUNT)
        if (availableTasks.data.data.tasks_available) {
          const response = await API.post(endpoints.LABELLING_ASSIGN)
          history.push(`/review/${response.data.data.ecg_id}${labelQueryParam}`)
          setTimeout(() => {
            window.location.reload(false)
          }, 100)
          onClose()
          return
        }

        setSubmitState(submitStates.DONE)
      } catch (e) {
        if (e.response?.status === 401) {
          API.get(endpoints.LOGOUT).finally((e) => {
            removeCookie(SESSION_COOKIE)
            dispatch(setNotAuthenticated())
          })
        }
      }
    } else {
      try {
        const assignedTasks = await API.get(endpoints.TASKSTATUS)
        if (assignedTasks.data.data.length) {
          history.push(
            `/review/${assignedTasks.data.data[0].ecg_id}${auditQueryParam}`
          )
          setTimeout(() => {
            window.location.reload(false)
          }, 100)
          onClose()
          return
        }
      } catch (e) {
        if (e.response?.status === 401) {
          API.get(endpoints.LOGOUT).finally((e) => {
            removeCookie(SESSION_COOKIE)
            dispatch(setNotAuthenticated())
          })
        }
      }

      try {
        const availableTasks = await API.get(endpoints.GETTASKS)
        if (availableTasks.data.data.tasks_available) {
          const response = await API.get(endpoints.TASKASSIGN)
          history.push(`/review/${response.data.data.ecg_id}${auditQueryParam}`)
          setTimeout(() => {
            window.location.reload(false)
          }, 100)
          onClose()
          return
        }

        setSubmitState(submitStates.DONE)
      } catch (e) {
        if (e.response?.status === 401) {
          API.get(endpoints.LOGOUT).finally((e) => {
            removeCookie(SESSION_COOKIE)
            dispatch(setNotAuthenticated())
          })
        }
      }
    }
  }

  useEffect(() => {
    if (sumbitStateProps === submitStates.DONE) {
      handleSaveDone()
    } else {
      setSubmitState(sumbitStateProps)
    }
  }, [sumbitStateProps])

  const useStyles = makeStyles((theme) => ({
    modal: {
      minWidth: 400,
    },
    inProgressModal: {
      margin: theme.spacing(1),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    bigText: {
      fontSize: 16,
      color: theme.palette.grey[900],
      marginBottom: theme.spacing(1),
      lineHeight: 1,
    },
    smallText: {
      fontSize: 12,
      marginBottom: 0,
    },
    bigIcon: {
      fontSize: 60,
      marginRight: theme.spacing(2),
    },
  }))

  const classes = useStyles()

  const handleClose = () => {
    onClose()
  }

  let errorWarningText = 'You have '
  if (reviewErrors.length) {
    errorWarningText += `${reviewErrors.length} error${
      reviewErrors.length > 1 ? 's' : ''
    }`
    if (reviewWarnings.length) {
      errorWarningText += ` and`
    }
  }
  if (reviewWarnings.length) {
    errorWarningText += ` ${reviewWarnings.length} warning${
      reviewWarnings.length > 1 ? 's' : ''
    }`
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="loader-dialog">
      {(submitState === submitStates.IN_PROGRESS ||
        submitState === submitStates.ERROR) && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          size="large">
          <CloseIcon />
        </IconButton>
      )}
      {submitState === submitStates.IN_PROGRESS && (
        <DialogContent
          className={`${classes.modal} ${classes.inProgressModal}`}
        >
          <Grid container alignItems="center">
            <Grid item>
              <CircularProgress className={classes.bigIcon} />
            </Grid>
            <Grid item>
              <DialogContentText className={classes.bigText}>
                Saving data...
              </DialogContentText>
              <DialogContentText className={classes.smallText}>
                Don't close this tab before saving ends.
              </DialogContentText>
            </Grid>
          </Grid>
        </DialogContent>
      )}
      {submitState === submitStates.DONE && (
        <>
          <DialogContent className={classes.modal}>
            <Grid container alignItems="center">
              <Grid item>
                <MoodIcon color="primary" className={classes.bigIcon} />
              </Grid>
              <Grid item>
                <DialogContentText className={classes.bigText}>
                  You finished all task from the queue!
                </DialogContentText>
                <DialogContentText className={classes.smallText}>
                  There are no more tasks in the queue for you.
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={MakeRedirect(isAudit ? '/audit' : `/`)}
              color="primary"
              variant="contained"
            >
              Go to all tasks
            </Button>
          </DialogActions>
        </>
      )}
      {submitState === submitStates.ERROR && (
        <>
          <DialogContent className={classes.modal}>
            <Grid container alignItems="center">
              <Grid item>
                <HighlightOffIcon
                  style={{ color: red[900] }}
                  className={classes.bigIcon}
                />
              </Grid>
              <Grid item>
                <DialogContentText className={classes.bigText}>
                  We can’t save your review
                </DialogContentText>
                <DialogContentText className={classes.smallText}>
                  Check your internet connection and try again.
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setForceSubmit(Date.now())}
              color="primary"
              variant="contained"
            >
              Retry
            </Button>
          </DialogActions>
        </>
      )}
      {submitState === submitStates.ERROR_ECG_ERROR && (
        <>
          <DialogContent className={classes.modal}>
            <Grid container alignItems="center">
              <Grid item>
                <HighlightOffIcon
                  style={{ color: reviewErrors.length ? red[900] : '#EF6C00' }}
                  className={classes.bigIcon}
                />
              </Grid>
              <Grid item>
                <DialogContentText className={classes.bigText}>
                  {errorWarningText}.
                </DialogContentText>
                {reviewErrors.map((error) => (
                  <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                    style={{ fontSize: 12 }}
                  >
                    <Grid item>
                      <Typography
                        color="secondary"
                        style={{ lineHeight: 1, fontSize: 'inherit' }}
                      >
                        <HighlightOffIcon />
                        &nbsp;
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography
                        color="secondary"
                        style={{ fontSize: 'inherit' }}
                      >
                        Error:&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ fontSize: 'inherit' }}>
                        {error.text}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
                {reviewWarnings.map((warning) => (
                  <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                    style={{ fontSize: 12 }}
                  >
                    <Grid item style={{ color: '#EF6C00' }}>
                      <Typography
                        color="inherit"
                        style={{ lineHeight: 1, fontSize: 'inherit' }}
                      >
                        <HighlightOffIcon />
                        &nbsp;
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography
                        color="inherit"
                        style={{ fontSize: 'inherit' }}
                      >
                        Warning:&nbsp;
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ fontSize: 'inherit' }}>
                        {warning.text}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {reviewErrors.length ? (
              <Button
                onClick={onFixErrorsClick}
                color="primary"
                variant="contained"
              >
                Let's fix them
              </Button>
            ) : (
              <>
                <Button
                  onClick={onFixErrorsClick}
                  color="primary"
                  variant="outlined"
                >
                  Let's fix them
                </Button>
                <Button
                  onClick={handleSubmitNoValidation}
                  color="primary"
                  variant="contained"
                >
                  Submit with warnings
                </Button>
              </>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
