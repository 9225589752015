import React from 'react';
import { Group } from '@visx/group';
import { Area } from '@visx/shape';
import { AxisLeft, AxisBottom, AxisScale } from '@visx/axis';
import { LinearGradient } from '@visx/gradient';
import { curveMonotoneX } from '@visx/curve';
import { GridRows, GridColumns } from '@visx/grid';

// Initialize some variables
const axisColor = 'black';
const axisBottomTickLabelProps = {
  textAnchor: 'middle' ,
  fontFamily: 'Arial',
  fontSize: 10,
  fill: axisColor,
};
const axisLeftTickLabelProps = {
  dx: '-0.25em',
  dy: '0.25em',
  fontFamily: 'Arial',
  fontSize: 10,
  textAnchor: 'end',
  fill: axisColor,
};

// accessors
const getDate = (d) => d.x;
const getStockValue = (d) => d.y ;

export default function AreaChart({
  data,
  gradientColor,
  width,
  yMax,
  margin,
  xScale,
  yScale,
  hideBottomAxis = false,
  hideLeftAxis = false,
  top,
  left,
  children,
  grids=false
}) {
  if (width < 2) return null;
  return (
    <Group left={left || margin.left} top={top || margin.top}>
      <LinearGradient
        id="gradient"
        from={gradientColor}
        fromOpacity={1}
        to={gradientColor}
        toOpacity={0.5}
      />
   
      {grids &&<><GridRows scale={yScale} width={width} height={yMax} stroke="#e0e0e0" />
      <GridColumns scale={xScale} numTicks={50} height={250} stroke="#e0e0e0" /> 
        <AxisBottom
            scale={xScale}
            numTicks={10}
            stroke={axisColor}
            tickStroke={axisColor}
            tickLabelProps={() => axisBottomTickLabelProps}
          /> </>}
      <Area
        data={data}
        strokeWidth={1.5}
        stroke="red"
        x={(d) => xScale(getDate(d)) || 0}
        y={(d) => yScale(getStockValue(d)) || 0}
        yScale={yScale}
      />
      {children}
    </Group>
  );
}
