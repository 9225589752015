import React, { useState } from 'react'
import { Grid } from '@mui/material'
import ColCard from '../ColCard'
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'

const minWidth = 50

const useStyles = makeStyles((theme) => ({
  resizing: {
    height: 'auto',
  },
  card: {
    height: 'auto',
    margin: theme.spacing(),
  },
}))

function CollapseHorizontal(props) {
  const classes = useStyles()
  const { maxWidth, title, isAudit, resizing, className } = props
  const [isOpen, setIsOpen] = useState(false)

  const onResize = (isOpen) => {
    setIsOpen(isOpen)
  }

  const passedProps = {
    title,
    onResize,
    maxWidth,
  }

  if (!isAudit) {
    return props.solid
  }

  return (
    <Grid container className={clsx(className && className)}>
      <Grid
        item
        style={{
          width: `calc(100% - ${
            isOpen ? props.maxWidth + 30 : minWidth + 30
          }px)`,
        }}
      >
        {props.solid}
      </Grid>
      <Grid
        item
        className={classes.resizing}
        style={{ width: `${isOpen ? props.maxWidth : minWidth}px` }}
      >
        <ColCard
          {...passedProps}
          collapsable
          collapseNoDivider
          horizontalCollapse
          defaultExpanded={false}
          className={classes.card}
          hasBorder={isOpen}
        >
          {resizing}
        </ColCard>
      </Grid>
    </Grid>
  )
}

export default CollapseHorizontal
