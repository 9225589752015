import React, { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { loadAuditAsync, selectAudits, clearAudits } from './audit-slice'

import Navigation from '../../components/Navigation'
import AuditTable from '../../components/AuditTable'
import Backdrop from '../../components/Backdrop'
import SectionWrapper from '../../components/SectionWrapper'

import { Grid, CardContent, Card, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { TasksContext } from '../../contexts/Tasks.context'
import TableHeader from '../../components/TableHeader'

const useStyles = makeStyles((theme) => ({
  // root: { flexGrow: 1, margin: theme.spacing() },
  toolbar: { ...theme.mixins.toolbar },
}))

export function AuditList() {

  const { auditTasks, getAuditTasks, setCursor} = useContext(TasksContext)

  useEffect(() => {
    getAuditTasks()
    const intervalId = setInterval(() => {
      getAuditTasks()
    }, 20000);
    return () => clearInterval(intervalId);
  }, [])


  return <>
    <SectionWrapper header={ <TableHeader title="Audit Tasks" />}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} align="center">
          <Card>
              <CardContent sx={{padding:"0"}}>
              <AuditTable {...{ audits: auditTasks, setCursor }} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </SectionWrapper>
  </>;
}
