import React, { useState, useEffect, useRef } from 'react'

import { Line } from 'react-chartjs-2'
import * as ChartAnnotation from 'chartjs-plugin-annotation'
import * as ChartAnnotationDraggable from 'chartjs-plugin-draggable'

import { isEqual } from 'lodash'

const layoutPadding = {
  top: 0,
  bottom: 0,
  left: 10,
  right: 10,
}

function LineChart(props) {
  const {
    labels,
    caliperState,
    tools,
    activeTool,
    doCaliperTool,
    doCaliperVertTool,
    setCurrentTime,
    toolMapping,
    toolState,
    startedIndex,    
    displayOnly = false,

  } = props

  const [data, setData] = useState([])
  const chartRef = useRef(null)
  const timeoutClickRef = useRef(null)

  const [domainStop, setDomainStop] = React.useState(10)

  const [minX, setMinX] = React.useState(0)
  const [maxX, setMaxX] = React.useState(domainStop > 10 ? 10 : domainStop)

  const [minY, setMinY] = React.useState(-1)
  const [maxY, setMaxY] = React.useState(5)

  const stepSizeY = props.isBig ? 375 : 75

  const onDoubleClick = (...args) => {
    if (props.onDoubleClick) {
      props.onDoubleClick(...args, {
        data: props.data,
        layoutPadding,
        startedIndex,
      })
    }
  }
  const onContextMenu = (...args) => {
    args[0].preventDefault()
    if (props.onContextMenu) {
      props.onContextMenu(...args, {
        data: props.data,
        layoutPadding,
        startedIndex,
      })
    }
  }

  function computeY(y, type = 'min') {
    const diff = y % stepSizeY
    if (diff &&  !displayOnly) {
      let newY = y - diff
      if (type === 'min') {
        newY = newY - stepSizeY
      } else if (type === 'max') {
        newY = newY + stepSizeY
      }
      console.log(newY)
      return newY
    }
    return y
  }

  useEffect(() => {    
    if (props.onMount) {
    props.onMount()
    }
    if (!displayOnly) {
      if (chartRef) {
        const canvas = chartRef.current?.chartInstance?.canvas
        if (canvas) {
          canvas.addEventListener('contextmenu', onContextMenu)
        }}

    return () => {
      const canvas = chartRef?.current?.chartInstance?.canvas
      if (canvas) {
        canvas.removeEventListener('contextmenu', onContextMenu)
      }
      if (timeoutClickRef?.current) {
        clearTimeout(timeoutClickRef.current)
      }
    }}}
       , [])

  useEffect(() => {
    if (!isEqual(props.data, data)) {
      setData(props.data)
    }
  }, [props.data])

  useEffect(() => {
    if (data.length > 0 && data[0] && data[0].length > 0) {
      setDomainStop(data[0][data[0].length - 1].x)

      if (props.minY) {
        setMinY(computeY(props.minY, 'min'))
      } else {
        setMinY(
          computeY(
            data.length > 0 ? Math.min(...data[0].map(({ y }) => y)) - 1 : -1,
            'min'
          )
        )
      }

      if (props.maxY) {
        setMaxY(computeY(props.maxY, 'max'))
      } else {
        setMaxY(
          computeY(
            data.length > 0 ? Math.max(...data[0].map(({ y }) => y)) + 1 : 5,
            'max'
          )
        )
      }
      setMinX(Math.min(...data[0].map(({ x }) => x)))
      setMaxX(Math.max(...data[0].map(({ x }) => x)))
    }
  }, [data])

  // Update annotation toolbar with hovered timestamp
  function handleHover(event, elements) {
    if (elements.length > 0) {

      setCurrentTime(data[0][elements[0]._index].x)
    }
    if (activeTool === toolMapping?.CALIPER) {
      doCaliperTool(event, elements, toolState)
    }
    if (activeTool === toolMapping?.CALIPERVERT) {
      doCaliperVertTool(event, elements, toolState)
    }
  }

  const handleClick = (event, elements) => {
    if (timeoutClickRef.current) {
      clearTimeout(timeoutClickRef.current)
      timeoutClickRef.current = null
      onDoubleClick(event)
    } else {
      const id = setTimeout(() => {
        tools[activeTool].clickHandler(event, elements, toolState, {
          startedIndex,
        })
        timeoutClickRef.current = null
      }, 200)
      timeoutClickRef.current = id
    }
  }

  return (
    <div>
      <Line
        id="task-caliper"
        ref={chartRef}        
        height={175}
        plugins={[ChartAnnotationDraggable]}

        options={{
          annotation: 
            {
          
            annotations: [
              ...Object.values(labels ? labels : {}),
              caliperState?.current ? caliperState.current : {},
              
            ],
          },
          layout: {
            padding: layoutPadding,
          },          
          maintainAspectRatio: false,

          elements: { point: { radius: 0 } },
          animation: { duration: 0 },
          responsiveAnimationDuration: 0,
          hover: {
            animationDuration: 0,
            intersect: false,
            mode: 'index',
          },
          legend: { display: false },          
          onClick: displayOnly ? undefined : handleClick,
          onHover: displayOnly ? undefined : handleHover,
          plugins: {
            crosshair: {
              line: {
                color: '#000000',
                width: 1,
                dashPattern: [5, 3],
              },
              sync: { enabled: false },
              zoom: { enabled: false },
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  // when false disable yAxis labels completly
                  display: false,
                  min: minY,
                  max: maxY,
                  stepSize:  Math.abs(maxY - minY) / (props.isBig ? 5 : 20),
                  maxTicksLimit: 70,
                  callback: function (value, index, __) {
                    if (index % 5 === 0) {
                      return Number(value).toFixed(2)
                    } else {
                      return ''
                    }
                  },
                },
              },
            ],
            xAxes: [
              {
                type: 'linear',
                ticks: {                  
                  min: displayOnly ? 0 : minX,
                  max: displayOnly ? 4 : maxX,
                  maxTicksLimit: 200,
                  stepSize: props.isBig ? 0.2 : 0.04,
                  autoSkip: false,
                  alignToPixels: true,
                  fontSize: 12,
                  callback: function (value, index, __) {
                    return value - parseInt(value) > 0 ? '' : `${value}s`
                  },
                },
              },
            ],
          },
          tooltips: { enabled: false },
        }}
        data={{
          datasets: [
            {
              borderColor: '#DA100B',
              borderWidth: 1,
              data: data[0],
              fill: false,
            },
          ],
        }}
      />
    </div>
  )
}

export default LineChart
