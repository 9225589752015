import { Snackbar, Alert, CssBaseline, Tab, Tabs, Typography } from '@mui/material'
import React, { useState } from 'react'
import SectionWrapper from '../../components/SectionWrapper'
import TasksTab from './TasksTab'
import { FormatListBulleted, People } from '@mui/icons-material';
import { useContext } from 'react'
import { AdminContext } from '../../contexts/Admin.context'
import TechniciansTab from './TechniciansTab';
const AdminTools = () => {
    const [activeTab, setActiveTab] = useState(0)
    const { success, failed } = useContext(AdminContext)
    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <div>
            <CssBaseline />

            <Snackbar open={success} autoHideDuration={2000} >
                <Alert everity="success" sx={{ width: '100%' }}>
                    Task priority has been changed
                </Alert>
            </Snackbar>

            <Snackbar open={failed} autoHideDuration={2000} >
                <Alert everity="error" sx={{ width: '100%' }}>
                    We can't save your change. Please try again.
                </Alert>
            </Snackbar>
            <SectionWrapper header={<Typography variant="h4" style={{ fontWeight: '500' }} >Admin Tools</Typography>}>    <Tabs style={{ height: "60px" }} value={activeTab} onChange={handleChange} aria-label="icon label tabs example">
                <Tab style={{ flexDirection: "row", gap: "10px" }} icon={<FormatListBulleted />} label="TASKS" />
                <Tab style={{ flexDirection: "row", gap: "10px" }} icon={<People />} label="TECHNICIANS" />
            </Tabs>

                {
                    !activeTab ? <TasksTab />
                        : <TechniciansTab />
                }
            </SectionWrapper>
        </div>
    )
}

export default AdminTools
