import React, { useEffect, createContext, useState, useContext } from 'react'

import { getActiveTasksService, getRemainingActiveTasks, assignTaskService, getRemainingLabelingTasks, getLabelingTasksService, assignLabelingTaskService, getRemainingPracticeTasks, getPracticeTasksService, assignPracticeTaskService, getAuditTasksService, unassignTaskService } from '../services/tasks'
import { AuthContext } from './Auth.context'

export const TasksContext = createContext(null)

const TasksProvider = ({ children }) => {
    const [activeTasks, setActiveTasks] = useState([])
    const [labelingTasks, setLabelingTasks] = useState([])
    const [practiceTasks, setPracticeTasks] = useState([])
    const [auditTasks, setAuditTasks] = useState([])
    const [remainingActiveTasksNumber, setRemainingActiveTasksNumber] = useState(null)
    const [remainingLabelingTasksNumber, setRemainingLabelingTasksNumber] = useState(null)
    const [remainingPracticeTasksNumber, setRemainingPracticeTasksNumber] = useState(null)
    const [loading, setLoading] = useState(false)
    const [cursor, setCursor] = useState(0)
    const { logout } = useContext(AuthContext)

    const getActiveTasks = () => {
        setLoading(true)
        getRemainingActiveTasks().then(setRemainingActiveTasksNumber).catch(()=>logout()).finally(() => setLoading(false))
        getActiveTasksService().then(setActiveTasks)
    }


    const getLabelingTasks = () => {
        setLoading(true)
        getRemainingLabelingTasks().then(setRemainingLabelingTasksNumber).catch(logout).finally(() => setLoading(false))
        getLabelingTasksService().then(data => setLabelingTasks(data.map(line=> ({...line, status:2}))))
    }


    const getPracticeTasks = () => {
        setLoading(true)
        getRemainingPracticeTasks().then(setRemainingPracticeTasksNumber).catch(logout).finally(() => setLoading(false))
        getPracticeTasksService().then(setPracticeTasks)
    }


    const getAuditTasks = () => {
        setLoading(true)
        getAuditTasksService(0).then(setAuditTasks).catch(logout).finally(() => setLoading(false))
    }
  const assignActiveTask = () => {
        setLoading(true)
        assignTaskService().then(getActiveTasks)
  }


  const assignPracticeTask = () => {
    setLoading(true)
    assignPracticeTaskService().then(getPracticeTasks)
}


  const assignLabelingTask = () => {
    setLoading(true)
    assignLabelingTaskService().then(getLabelingTasks)
}

const getAllTasks = () =>{
    getPracticeTasks()
    getActiveTasks()
    getLabelingTasks()
}

useEffect(() => {
    if(cursor){
        setLoading(true)
        getAuditTasksService(cursor).then(data => setAuditTasks(prev => [...prev, ...data])).finally(() => setLoading(false))
    }
}, [cursor])
const unassignTask = async (id, mode) => {
    try {
        await unassignTaskService(id, mode)
        getAllTasks()
        return true
        
    } catch (error) {
        console.log(error)
    }
}

    return (
        <TasksContext.Provider value={{unassignTask, getAuditTasks, setCursor, auditTasks, remainingActiveTasksNumber,remainingPracticeTasksNumber,practiceTasks,assignPracticeTask,remainingLabelingTasksNumber,getPracticeTasks,assignLabelingTask,labelingTasks, activeTasks, loading, assignActiveTask, getActiveTasks, getLabelingTasks }}>
            {children}
        </TasksContext.Provider>
    )
}

export default TasksProvider
