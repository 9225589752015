import React from 'react'

import Box from '@mui/material/Paper'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    pointerEvents: 'none',
    transform: 'translateY(-100%)',
    padding: "16px",
  },
}))

function Popup({ anchorEl, children, open }) {
  const classes = useStyles()
  if (!anchorEl || !open) {
    return null
  }
  const { x, y, width } = anchorEl.getBoundingClientRect()
  const style = { top: y - 10, right: 0 }
  return (
    <Box style={style} className={classes.wrapper}>
      {children}
    </Box>
  )
}

export default Popup
