import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import useAsyncReference from '../../hooks/useAsyncReference'
import debounce from 'lodash/debounce'
import { AspectRatioIconWrapper, BackdropWrapper, WrapperBox } from './AppWrapper.styled';

function AppWrapper({ children }) {
  const [showDimmer, setShowDimmer] = useAsyncReference(false)

  const checkIfWidthIsOk = () => {
    const isOk = window.innerWidth > 800
    if (isOk === showDimmer.current) {
      setShowDimmer(!isOk)
    }
  }

  const checkIfWidthIsOkDebounced = debounce(checkIfWidthIsOk, 200)

  useEffect(() => {
    checkIfWidthIsOk()
    window.addEventListener('resize', checkIfWidthIsOkDebounced)

    return () => {
      window.removeEventListener('resize', checkIfWidthIsOkDebounced)
    }
  }, [])

  return (
    <WrapperBox>
      <BackdropWrapper
        open={showDimmer.current}
        transitionDuration={0}
      >
        <AspectRatioIconWrapper />
        <Typography variant="h6" >
          Your browser is too small
        </Typography>
        <Typography>
          Resize your browser to be at least 800 pixels wide
        </Typography>
      </BackdropWrapper>
      {children}
    </WrapperBox>
  )
}

export default AppWrapper
