
import API from '../common/api'
import { createDataset } from '../common/utils'
const endpoints = require('../common/endpoints')


// active tasks services
export const getActiveTasksService = async () =>{
    const res = await API.get(endpoints.TASKSTATUS)
    return res.data.data
}

export const getRemainingActiveTasks = async () =>{
    const res = await API.get(endpoints.GETTASKS)
    return res.data.data.tasks_available
}

export const assignTaskService = async () =>{
    const res = await API.get(endpoints.TASKASSIGN)
    return res.data.data.ecg_id
}


// labeling tasks services
export const getLabelingTasksService = async () =>{
    const res = await API.get(endpoints.LABELLING_ASSIGNED)
    return res.data.data
}

export const getRemainingLabelingTasks = async () =>{
    const res = await API.get(endpoints.LABELLING_AVAILABLE_COUNT)
    return res.data.data.tasks_available
}


export const assignLabelingTaskService = async () =>{
    const res = await API.post(endpoints.LABELLING_ASSIGN)
    return res.data.data.ecg_id
}


// labeling tasks services
export const getPracticeTasksService = async () =>{
    const res = await API.get(endpoints.PRACTICE_ASSIGNED)
    return res.data.data
}

export const getRemainingPracticeTasks = async () =>{
    const res = await API.get(endpoints.PRACTICE_AVAILABLE_COUNT)
    return res.data.data.tasks_available
}


export const assignPracticeTaskService = async () =>{
    const res = await API.post(endpoints.PRACTICE_ASSIGN)
    return res.data.data.ecg_id
}


// audit tasks

export const getAuditTasksService = async (cursor) =>{
    const res = await API.get(endpoints.GET_AUDITS(cursor))
    return res.data.data
}
// single task 
export const getTaskService = async (id) =>{
    let res = await API.get(endpoints.GETECG(id))
    const dataset = await API.get(endpoints.GETRECORD_2(id))
    const rhythms =  await API.get(endpoints.RHYTHMS)
    return {...res.data.data ,dataset : createDataset(dataset.data.data, true), rhythms:  rhythms.data.data}
}


export const unassignTaskService =  async (id, mode) =>{
    let res = await  mode === 'practice' ?API.delete(endpoints.PRACTICE_UNASSIGN + id) :  API.get(endpoints.TASKUNASSIGN + id)
    return res.data
}


export const getEcgChartData = async (id) =>{
    let res = await API.get(endpoints.GETRECORD  + id)
    console.log([createDataset(res.data.data)])
}

