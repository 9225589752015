import React from 'react'
import { Divider, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';



const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
    height: "100%"
}));


const CustomTypography = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const Card = ({ content, title }) => {
    return (
        <Item variant="outlined">
            <CustomTypography variant="h6" component="h6">
                {title}
            </CustomTypography>
            <Divider />
            <CustomTypography>
                {content}
            </CustomTypography>
        </Item>
    )
}

export default Card
