import React from 'react'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Paper, TextField } from '@mui/material';
import PhoneInput from 'react-phone-input-2';

// import { Viewer, Worker } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const steps = ['Set your password', 'Add phone number', 'Sign agreement', 'Quill tutorial'];

const SetYourPassword = ({ setActiveStep }) => (
    <Paper sx={{ height: "400px", width: "406px", padding: "40px 30px", textAlign: "center", background: "white", display: "flex", justifyContent: "center", flexDirection: "column" }}>
        <Typography variant="h5" > Set your password</Typography>
        <Typography style={{ color: "grey", fontSize: "16px" }}>Create new password to <br />
            keep your account save</Typography>
        <TextField
            id="password"
            label="Password"
            type="password"
            style={{ marginTop: "40px" }}
            variant="outlined"
            fullWidth
            autoFocus
            size="small"
            required
        />
        <Typography sx={{ mb: 2, mt:1, ml:2 }} style={{ color: "grey", textAlign: "left", fontSize:"14px" }}>Your password needs to include number, uppercase<br/>
            character, special character and be 7 characters or longer</Typography>
        <TextField
            id="password_2"
            label="Confirm password"
            type="password"
            style={{marginTop:"10px"}}
            variant="outlined"
            size="small"
            fullWidth
            autoFocus
            required
        />
        <Button
            disableFocusRipple
            disableRipple
            style={{ marginTop: '40px' }}
            variant="contained"
            color="primary"
            onClick={() => setActiveStep(prev => prev + 1)}

        >
            change password
        </Button>
    </Paper>

)



const SetYourPhone = ({ setActiveStep }) => (
    <Paper sx={{ height: "290px", width: "376px", padding: "35px", textAlign: "center", background: "white", display: "flex", justifyContent: "center", flexDirection: "column" }}>
        <Typography variant="h5" >Add phone number</Typography>
        <Typography style={{ color: "grey", fontSize: "16px" }}>Make sure that number is current (You will be <br />
            able to change it later in your profile settings. <br /> We’ll use it to send you, your payments.</Typography>
       <div style={{width:"370px", margin:"20px auto"}}>
       <PhoneInput
            country={'us'}
            inputStyle={{ width:"100%",height: "40px" }}
        />
           </div> 
        <Button
            disableFocusRipple
            disableRipple
            style={{ marginTop: '40px' }}
            variant="contained"
            color="primary"
            onClick={() => setActiveStep(prev => prev + 1)}

        >
            add phone number
        </Button>
    </Paper>

)



const VideoTutorial = ({ setActiveStep }) => (
    <Paper sx={{ width: "600px", padding: "35px", textAlign: "center", background: "white", display: "flex", justifyContent: "center", flexDirection: "column" }}>
        <Typography variant="h5" >Quill tutotrial </Typography>
        <Typography style={{ color: "grey", fontSize: "16px" }}>Video below will introduce you to basics of Quill. Take your time <br/>watching it, when you ready to start click button below video.</Typography>
     
      <div style={{height:"328px", widht:"600px", background:"#C4C4C4", marginTop:"20px"}}>

      </div>
        <Button
            disableFocusRipple
            disableRipple
            style={{ marginTop: '40px' }}
            variant="contained"
            color="primary"
            onClick={() => setActiveStep(prev => prev + 1)}

        >
           I’m ready, open quill
        </Button>
    </Paper>

)

const Contract = ({ setActiveStep }) => {
    // const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return   <Paper sx={{padding: "35px", textAlign: "center", background: "white", display: "flex", justifyContent: "center", flexDirection: "column"}}>
    {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js">
         <div
             style={{
                 height: '650px',
                 width: '900px',
                 marginLeft: 'auto',
                 marginRight: 'auto',
             }}
         >
             <Viewer
                 fileUrl={`/sample.pdf`}
                 plugins={[defaultLayoutPluginInstance]}
             />
         </div>
               <Button
            disableFocusRipple
            disableRipple
            style={{ marginTop: '40px' }}
            variant="contained"
            color="primary"
            onClick={() => setActiveStep(prev => prev + 1)}

        >
           Sign agreement
        </Button>
     </Worker> */}
 </Paper>
}
  



export default function Register() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (

        <>
            <Box sx={{ width: '100%', boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05), 0px 0.5px 1px rgba(0, 0, 0, 0.03)" }}>
                <Box sx={{ width: "900px", margin: "auto", padding: "25px 0 " }}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Box>
            </Box>
            <Box sx={{ background: "#D9DED922", height: "85vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {activeStep === 0 && <SetYourPassword setActiveStep={setActiveStep} />}
                        {activeStep === 1 && <SetYourPhone setActiveStep={setActiveStep} />}
                        {activeStep === 2 && <Contract setActiveStep={setActiveStep} />}
                        {activeStep === 3 && <VideoTutorial setActiveStep={setActiveStep} />}
                        
                        
                        {/* <Typography sx={{ mt: 0, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                        {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box> */}
                    </React.Fragment>
                )}</Box></>
    );
}