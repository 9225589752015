import React, { useEffect, createContext, useState } from 'react'
import useLocalStorage from '../hooks/useLocalStorage'
import API from '../common/api'
import { useCookies } from 'react-cookie'
import { SESSION_COOKIE } from '../common/constants'
import { useHistory } from 'react-router-dom'
import { agreePiiaService } from '../services/auth'


export const AuthContext = createContext(null)

const endpoints = require('../common/endpoints')

const AuthProvider = ({ children }) => {
  const [authenticated, setAuthentificated] = useLocalStorage('authenticated', false)
  const [role, setRole] = useLocalStorage('quill_role')
  const [username, setUsername] = useLocalStorage('username')
  const [piiaAgreement, setPiiaAgreement] = useLocalStorage('piia', false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies()
  const history = useHistory()
  const login = ({ user, password }) => {
    setLoading(true)
    API.post(endpoints.LOGIN, { username: user, password })
      .then(
        (e) => {
          setAuthentificated(true)
          setRole(e.data.data.role)
          setUsername(e.data.data.username)
          setPiiaAgreement(e.data.data.piia_agreed)
          setCookie(SESSION_COOKIE, e.data.data.token, {
            domain: endpoints.Host,
            path: '/',
            sameSite: 'strict',
            expires: new Date(Date.now() + (6 * 60 * 60 * 1000)),
          })
        },
        (e) => {
          setError(e.response.data.msg === ''? false : e.response.data.msg)
        }
      ).finally(() => setLoading(false))
  }

  const logout = () => {
    setLoading(true)

    API.get(endpoints.LOGOUT)
      .then(
        (e) => {
          removeCookie(SESSION_COOKIE)
          console.log('logout')
          localStorage.removeItem('authenticated')
          localStorage.removeItem('username')
          history.push('/')
        },
        (e) => {
          localStorage.removeItem('authenticated')
          console.log(e.response.data.msg)
        }
      )
      .finally(() => setLoading(false))
  }

  const piiaOnAgree = async() =>{
    const res = await agreePiiaService()
    console.log(res)
    if(res.code === 200){
      setPiiaAgreement(true)
    }
  }
  return (
    <AuthContext.Provider value={{error, piiaOnAgree, piiaAgreement, authenticated, role, username, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
