import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectRhythms } from '../../store/slices/rhythms'

import { Box, Grid, Typography, Chip, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

import Backdrop from '../Backdrop'
import ColCard from '../ColCard'
import LineChart from '../LineChart'

import { getLabelColorByCategory } from '../../common/utils'
import { splitFrequency } from '../../common/constants'

import * as endpoints from '../../common/endpoints'
import API from '../../common/api'
import { createDataset } from '../../common/utils'
import { Link, useHistory } from 'react-router-dom';

const useStylesLI = makeStyles((theme) => ({
  title: {
    minHeight: 25,
  },
}))

function LabeledInfo({ title, content, renderContent, className }) {
  const classes = useStylesLI()
  return (
    <Box
      style={{ display: 'flex', flexDirection: 'column' }}
      className={className}
    >
      <Box className={classes.title}>
        <Typography variant="caption" color="textSecondary">
          {title}
        </Typography>
      </Box>
      <Box>
        {renderContent ? (
          renderContent()
        ) : (
          <Typography variant="body2" color="textPrimary">
            {content}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {},
  chartWrapper: {
    '& canvas': {
      height: '200px !important',
    },
  },
  contentWrapper: {
    position: 'relative',
    padding: `calc(${theme.spacing() * 3}px ${theme.spacing()} * 1.5)`,
  },
  iconCircle: {
    marginTop: 3,
    borderRadius: '50%',
    width: 40,
    height: 40,
    backgroundColor: 'rgba(71, 96, 194, 0.15)',
    overflow: 'hidden',
    color: '#0F56B3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chip: {
    borderRadius: 4,
    marginRight: "5px",
  },
  chipWrapper: {
    margin: "5px",
  },
  tagsBottom: {
    marginTop: theme.spacing() * 3,
  },
}))

function UserHistoryItem(props) {
  const { ecgId, index, date, interpretation, tags, userNote, media } = props
  const allRhythms = useSelector(selectRhythms)
  const classes = useStyles()
  const firstTimeOpenRef = useRef(false)
  const [isBackdropOpen, setIsBackdropOpen] = useState(false)
  const [chartData, setChartData] = useState([])
  const history = useHistory()
  const colCardStyles = {
    padding: 8,
  }

  if (index % 2) {
    colCardStyles.backgroundColor = '#F7F8FA'
  }


  function isPDF(url) {
    return (url.match(/^http[^\?]*.(pdf)(\?(.*))?$/gmi) !== null);
  }

  function handleCollapse(isExpanded) {
    if (!firstTimeOpenRef.current && isExpanded && !media ) {
      firstTimeOpenRef.current = true
      setIsBackdropOpen(true)
      API.get(endpoints.GETRECORD + ecgId).then((data) => {
        data = createDataset(data.data.data)
        setChartData(data)
        setIsBackdropOpen(false)
      })
    }
  }

  return (
    <Grid item xs container>
      <ColCard
        className={classes.card}
        collapsable
        onResize={handleCollapse}
        withLine
        noWrapperMargin
        noWrapperRadius
        noChildrenPadding
        headerNoPadding={false}
        headerStyles={colCardStyles}
        defaultExpanded={false}
        collapseNoDivider={index % 2}
        renderHeader={{
          withoutTitle: true,
          render: () => (
            <Grid item xs container alignItems="flex-start" sx={{ 
                margin:"15px 10px"
            }}>
              <Grid item xs style={{ maxWidth: 60 }}>
                <Box className={classes.iconCircle}>
                  <DescriptionOutlinedIcon />
                </Box>
              </Grid>
              <Grid item xs style={{ maxWidth: 240 }}>
                <LabeledInfo
                  title="DATE"
                  content={
                    new Date(date).toLocaleDateString('en-US', {day: 'numeric',month: 'long', year: 'numeric'}) +
                    ' ' +
                    new Date(date)
                      .toLocaleTimeString()
                      .split('')
                      .slice(0)
                      .join('')
                  }
                />
              </Grid>
              <Grid item xs>
                <LabeledInfo
                  title="INTERPRETATION"
                  content={
                    <Grid container>
                      {interpretation.map((rhythm, index) => (
                        <Grid item className={classes.chipWrapper}>
                          <Chip
                            key={index}
                            size="small"
                            icon={
                              <FiberManualRecordIcon
                                style={{
                                  color: getLabelColorByCategory(
                                    allRhythms.find((r) => r.id === rhythm.id)
                                      ?.category
                                  ),
                                }}
                              />
                            }
                            variant="outlined"
                            label={rhythm.name}
                            className={classes.chip}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  }
                />
              </Grid>
              <Grid item xs={3} style={{ maxWidth: 500 }}>
                <LabeledInfo
                  title="TAGS"
                  content={
                    <Grid container>
                      {tags.map((tag, index) => (
                        <Grid item className={classes.chipWrapper} sx={{marginRight:"5px"}}>
                          <Chip key={index} size="small" label={tag} className={classes.chip} />
                        </Grid>
                      ))}
                    </Grid>
                  }
                />
              </Grid>
            </Grid>
          ),
        }}
      >
        <Box className={classes.contentWrapper} sx={{margin:"20px"}}>
          <Backdrop alignToParent open={isBackdropOpen} />
          <Grid container>
            <Grid item xs className={classes.chartWrapper} sx={{borderRadius:"6px",position:"relative",padding:"10px", background:"rgba(233, 234, 236, 0.30)",border: '1px solid #E9EAEC'}}>
              {media ?(isPDF(media) ? <div style={{width:" 100%", height:"200px", display:"flex", justifyContent:"center", alignItems:"center"}}><strong>Media File : PDF </strong></div> :<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}> <img src={media} style={{ height:"200px"}}/></div>)  : <LineChart
                displayOnly
                data={[chartData.slice(0, splitFrequency)]}
              />}
              <Button onClick={() => window.open(`/review/${props.ecgId}?mode=${props.mode}`, "_blank")} style={{position:"absolute", bottom:"10px", right:"10px", display:"flex", gap:"10px"}} variant="contained" color="primary"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 8V0H10L13.29 3.29L3.29 13.29L0 10V18H8L4.71 14.71L14.71 4.71L18 8Z" fill="white"/>
</svg>
 Open full ECG</Button>
            </Grid>
            <Grid
              item
              xs
              style={{ minWidth: 150, maxWidth: 500, marginLeft: 20 }}
              container
              flexDirection="column"
              direction="column"
            >
              <Grid item>
                <Typography variant="h6">User's note</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="textSecondary" sx={{paddingTop:"10px"}}>
                  {userNote}
                </Typography>
              </Grid>
              <Grid item 
                  sx={{marginTop:"20px"}}>
                <LabeledInfo
                  className={classes.tagsBottom}
                  title="TAGS"
                  content={
                    <Grid container>
                      {tags.map((tag, index) => (
                        <Grid item className={classes.chipWrapper}>
                          <Chip key={index} size="small" label={tag} className={classes.chip} sx={{marginRight:"5px"}} />
                        </Grid>
                      ))}
                    </Grid>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ColCard>
    </Grid>
  );
}

export default UserHistoryItem
