import React, { useState, useEffect, useRef } from 'react'

import { Alert, Box, Grid, TextField, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';

import CheckIcon from '@mui/icons-material/Check'

import clsx from 'clsx'

import MultipleSelect from '../MultipleSelect'
import Popup from '../Popup'
import SanityLabel from '../SanityLabel'

import useResizeObserver from '../../hooks/useResizeObserver'
import { sanityErrors as sanityErrorsEnum } from '../../common/enums'
import { useHistory } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const cheatSheetUrl =
  'https://docs.google.com/spreadsheets/d/1tkLcp_rx5ZUYCGfy1OPDnY_fJrxO-lb4/edit#gid=1322797949'
const warmTemplatesUrl =
  'https://docs.google.com/document/d/1SnLmc_3vMogDzzLYoul-BIPpsyypL6bi/edit'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px -1px 2px 0px #0000000D',
    zIndex: 100,
  },
  fullHeight: {
    height: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  bigButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#fff',
    backgroundColor: '#0F56B3',
  },
  buttonDisabled: {
    opacity: 0.7,
    cursor: 'default',
  },
  gridCol1: {
    paddingTop: theme.spacing() * 2,
    paddingBottom: theme.spacing() * 2,
    paddingLeft: theme.spacing() * 2,
  },
  gridCol2: {
    padding: theme.spacing() * 2,
  },
  textField: {
    width: '100%',
    margin: "10px"
  },
  helpText: {
    fontSize: '1rem',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  link: {
    textDecoration: 'none',
  },
  sanityErrorsTitle: {
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    marginBottom: theme.spacing(),
  },
  alert:{
    "& .MuiAlert-icon": {
      marginRight: "5px"
    }, 
    paddingLeft: "10px", 
    paddingTop:"0px",
    width: "100%", 
    background: "white", 
    fontSize: "14px", 
    color: "rgba(1, 13, 61, 0.61)",
    fontWeight: 500,
    alignItems: "center"
  }
}))

function InterpretationBar(props) {
  const {
    sanityErrors,
    setSnackbarECGView,
    validateSanityError,
    warmMessageRef,
    analysisText,
    isLabelling,
    mode,
    finished,
    isAudit
  } = props
  const classes = useStyles()

  const wrapperRef = useRef(null)
  const buttonRef = useRef(null)

  const [warmMessage, setWarmMessage] = useState('')
  const [warmMessageValueType, setWarmMessageValueType] = useState('value')
  const [placeholderHeight, setPlaceholderHeight] = useState(0)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [barWidth, barHeight] = useResizeObserver(wrapperRef)

  useEffect(() => {
    setPlaceholderHeight(barHeight + 8) // 8 is default theme margin
  }, [barHeight, barWidth])

  useEffect(() => {
    if (analysisText !== warmMessage) {
      setWarmMessage(analysisText)
      const isError = !analysisText.length
      validateSanityError(isError, sanityErrorsEnum.NO_WARM_MESSAGE)
    }
  }, [analysisText])

  const isSubmitDisabled = sanityErrors?.length && mode !== "tutorial"

  const history = useHistory()
  const handleSubmit = () => {
    if (finished) {
      history.push('/practice')
    }
    else if (!isSubmitDisabled) {
      props.handleSubmit()
    }
  }

  function handleChange(e) {
    const { value } = e.target
    const isError = !value.length
    validateSanityError(isError, sanityErrorsEnum.NO_WARM_MESSAGE)
    setWarmMessage(value)
    warmMessageRef.current = value
  }

  const renderText = () => {
    if (finished) {
      return 'Start next Task'
    }
    if (mode === 'practice') {
      return 'Show Answers'
    }
    else if (mode === 'audit') {
      return 'Close Review'
    }
    else if (mode === 'tutorial') {
      return 'Finish Tutorial'

    }
    return 'Finish Review'
  }

  return (
    <Box style={{ marginTop: placeholderHeight }}>
      <Box className={classes.wrapper} ref={wrapperRef}>
        <Grid container className={classes.fullHeight} wrap="nowrap">
          <Grid
            item
            xs={isLabelling ? 14 : 6}
            className={clsx(isLabelling ? classes.gridCol2 : classes.gridCol1, classes.fullHeight)}
            container
            alignItems="center"
          >
            <MultipleSelect
              ekgid={props.id}
              selectedRecordLabels={props.activeRecordLabel}
              onChange={props.onRhythmsChange}
              setSnackbarECGView={setSnackbarECGView}
            />
            <Alert className={classes.alert}  icon={<InfoOutlinedIcon sx={{ color: "grey " }} />}  >Need help?{' '}
              <a href={cheatSheetUrl} target="blank" className={classes.link}>
                Open cheat sheet
              </a>{' '}
              or{' '}
              <a
                href={warmTemplatesUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Open warm templates
              </a>
            </Alert>

          </Grid>
          {!isLabelling && <Grid

            item
            xs={7}
            className={classes.gridCol2}
            container
            alignItems="center"
          >
            <TextField
              label="Warm message*"
              id="task-message"
              placeholder="Warm message*"
              variant="outlined"
              className={clsx(classes.textField, classes.fullHeight)}
              maxRows={10}
              onChange={handleChange}
              multiline
              onFocus={() => setWarmMessageValueType('defaultValue')}
              onBlur={() => setWarmMessageValueType('value')}
              {...{ [warmMessageValueType]: warmMessage }}
            />
          </Grid>
          }
          <Grid item container style={{ flexBasis: 200 }} id="task-submit">
            <Grid
              item
              className={clsx(
                classes.fullWidth,
                classes.bigButton,
                isSubmitDisabled && classes.buttonDisabled
              )}
              onClick={handleSubmit}
              ref={buttonRef}
              onMouseEnter={() => setIsMouseOver(true)}
              onMouseLeave={() => setIsMouseOver(false)}
              style={finished ? { background: '#4CAF50' } : {}}
            >
              <Grid container alignItems="center" justifyContent="center" >
                <CheckIcon />
                {renderText()}
              </Grid>
            </Grid>
          {mode !== " tutorial" &&  <Popup
              open={sanityErrors?.length && isMouseOver}
              anchorEl={buttonRef.current}
              sx={{margin: "20px"}}
            >
              <Typography className={classes.sanityErrorsTitle}>
                To submit review you have to:
              </Typography>
              <SanityLabel
                isError={sanityErrors.includes(
                  sanityErrorsEnum.NO_RHYTHM_SELECTED
                )}
              >
                Select rhythm
              </SanityLabel>
              {!isLabelling && <SanityLabel
                isError={sanityErrors.includes(
                  sanityErrorsEnum.NO_WARM_MESSAGE
                )}
              >
                Add warm message
              </SanityLabel>}
              <SanityLabel
                isError={sanityErrors.includes(sanityErrorsEnum.EMPTY_PQRST)}
              >
                Fill at least one PQRST input
              </SanityLabel>
              <SanityLabel
                isError={sanityErrors.includes(sanityErrorsEnum.NO_FULL_SCROLL)}
              >
                Scroll to last chart
              </SanityLabel>
            </Popup>} 
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default InterpretationBar
