import React, { useEffect, useContext } from 'react'
import {
  Grid,
  Card,
  CardContent,
  CssBaseline,
} from '@mui/material';
import SectionWrapper from '../../components/SectionWrapper'
import { TasksContext } from '../../contexts/Tasks.context';
import TableHeader from '../../components/TableHeader';
import TaskTable from '../../components/TaskTable';
import Tutorial from '../../components/Tutorial';

export function Dashboard() {
  const { remainingActiveTasksNumber, activeTasks, assignActiveTask, getActiveTasks } = useContext(TasksContext)

  useEffect(() => {
    getActiveTasks()
  }, [])

  return (
    <div>
      <CssBaseline />
      <SectionWrapper header={<TableHeader title="Live Tasks" counter={remainingActiveTasksNumber} getNewTaskHandler={assignActiveTask}/>}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} align="center">
            <Card >
              <CardContent sx={{padding:"0"}}>
                <TaskTable {...{ taskData : activeTasks, mode: 'live'}} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </SectionWrapper>
    </div>
  );
}
