export const APIURL = '/api'

export const LOGIN = '/user/login'
export const LOGOUT = '/user/logout'
export const TASKSTATUS = '/task/assigned'
export const TASKASSIGN = '/task/assign'
export const TASKUNASSIGN = '/task/unassign/'
export const GETRECORD = '/record/get/'
export const SETANALYSIS = '/annotation/analysis/set/'
export const ADDANNOTATIONLABEL = '/annotation/add/'
export const SETRECORDLABEL = '/annotation/record-label/set/'
export const SETNOTES = '/annotation/note/set/'
export const GETNOTES = '/annotation/note/get/'
export const GETHEARTRATE = '/record/avg-heart-rate/'
export const GETTASKS = '/task/available'
export const SETINTERVALS = '/annotation/interval-measures/set/'
export const RHYTHMS = '/annotation/rhythms'
export const GETANNOTATIONSETTER = (id) => `/v1/ecg/${id}/analysis`
export const GET_AUDITS = (cursor) => `/v1/audits?cursor=${cursor}`
export const AUDIT = (ecg_id) => `/v1/ecg/${ecg_id}/audits`
export const AUDIT_2 = (ecg_id) => `/v2/ecg/${ecg_id}/audits`


export const SENDEVENT = (id) => `/v1/ecg/${id}/events`
export const USERINFO = '/v1/user/me'
export const USERSTATS = '/v1/user/me/stats'
export const GETUSERWARMMESSAGES = (cursor) =>
  `/v1/user/me/warm-messages?cursor=${cursor}`
export const GET_USER_HISTORY = (id, cursor) =>
  `/v1/ecg/${id}/user-history${
    typeof cursor === 'number' ? `?cursor=${cursor}` : ''
  }`

  // unified API
export const GETECG = (id) => `/v1/ecg/${id}`

export const GETRECORD_2 = (id) => `/record/get/${id}`
// unified API Audit
export const GETECG_2 = (id) => `/record/get/${id}`

// obsolete - delete when deleting EKGViewerOLD.js
export const GETINTERVALS = '/annotation/interval-measures/get/'
export const GETANALYSIS = '/annotation/analysis/get/'
export const GETRECORDLABEL = '/annotation/record-label/get/'
export const GETANNOTATIONLABEL = '/annotation/get/'

// labelling
export const LABELLING_ASSIGNED = '/v1/labelling/assigned'
export const LABELLING_ASSIGN = '/v1/labelling/assign'
export const LABELLING_AVAILABLE_COUNT = '/v1/labelling/available/'
export const LABELLING_UNASSIGN = '/v1/labelling/unassign/'
export const LABELLING_COMPLETE = (id) => `/v1/labelling/complete/${id}`

// practice
export const PRACTICE_ASSIGNED = '/v1/practice/assigned'
export const PRACTICE_ASSIGN = '/v1/practice/assign'
export const PRACTICE_AVAILABLE_COUNT = '/v1/practice/available/'
export const PRACTICE_UNASSIGN = '/v1/practice/unassign/'
export const PRACTICE_COMPLETE = (id) => `/v1/practice/complete/${id}`


// admin tools
export const ADMIN_TASKS =  '/v1/admin/ecg'
export const ECG_PRIORITY =  (id) => `/v1/admin/ecg/${id}/priority`
export const LIST_TECHNICIANS = '/v1/admin/users'
export const GET_TECHNICIAN = (id) => `/v1/admin/users/${id}`
export const SAVE_TECHNICIAN = (id) => `/v1/admin/users/${id}`
export const ADD_TECHNICIAN = '/v1/admin/users'
export const ASSIGN_TECHNICIAN = (id) => `/v1/admin/ecg/${id}/assign`


// piia
export const PIIA_AGREEMENT = `/v1/user/me/agree-piia`