import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useCookies } from 'react-cookie'
import { compare } from '../../common/utils'

import { getRhythms } from '../../store/slices/rhythms'

import { Box, TextField, Chip, Snackbar, Alert } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import ClearIcon from '@mui/icons-material/Clear'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox';

import Backdrop from '../Backdrop'
import UniversalDialog from '../UniversalDialog'

import { getLabelColorByCategory } from '../../common/utils'

const useStyles = makeStyles((theme) => ({
  listItem: {
    margin: 5,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  listItemHeader: {
    pointerEvents: 'none',
    opacity: 0.5,
    cursor: 'default',
  },
  chip: {
    margin: 2,
    border: 'none',
  },
}))

function  MultipleSelect({
  selectedRecordLabels,
  ekgid,
  onChange,
  setSnackbarECGView,
}) {
  const [currentRecordLabels, setCurrentRecordLabels] = useState([])
  const [availableRecordLabels, setAvailableRecordLabels] = useState([])
  const [isBackdropOpen, setIsBackdropOpen] = useState(false)
  const [dialogState, setDialogState] = useState({
    open: false,
    smallText: null,
    addThisRhythm: null,
  })

  const classes = useStyles()
  const [cookies, setCookie, removeCookie] = useCookies()
  const dispatch = useDispatch()

  const [snackbar, setSnackBar]  = useState(false)
  const changeRecordLabels = (labels) => {
    onChange(labels)
    setCurrentRecordLabels(labels)
  }

  const handleChange = (event, value, test, {option}) => {
    const added =
      value.length > currentRecordLabels.length
        ? value.find((label) => !currentRecordLabels.includes(label))
        : null

    if (added?.require_confirmation) {
      const addedName = added?.name
      return setDialogState({
        open: true,
        smallText: (
          <>
            <div>
              Users are unhappy when we tell them <b>{addedName}</b>.
            </div>
            <div>
              Please double check if the scan is <b>{addedName}</b> before
              adding the label.
            </div>
            <div>
              <b>Tip</b>: If the amplitude is low, have you tried zooming into
              the scan?
            </div>
          </>
        ),
        addThisRhythm: added,
      })
    }

    console.log(option.group)
    if(currentRecordLabels.filter(label => label.group === option.group).length >= 1 && option.group && added ){
      setSnackBar('Cannot have rhythms from same group')
    }else{
      changeRecordLabels(value)

    }
  }

  useEffect(() => {
    const labels = []
    selectedRecordLabels.forEach((label) => {
      const object = availableRecordLabels.find(
        (availableLabel) => availableLabel.name === label
      )
      if (object) {
        labels.push(object)
      }
    })
    setCurrentRecordLabels(labels)
  }, [selectedRecordLabels, availableRecordLabels])

  useEffect(() => {
    setIsBackdropOpen(true)
    getRhythms()
      .then((resp) => {
        setIsBackdropOpen(false)
        const filtered = (resp || [])
          .filter((data) => data.visible)
          .filter((data) => data.position > 0)
          .sort(compare)
        setAvailableRecordLabels(filtered)
      })
      .catch(() => {
        setIsBackdropOpen(false)
      })
  }, [])

  const handleAddLabelFromModal = () => {
    const labels = [...currentRecordLabels, dialogState.addThisRhythm]
    changeRecordLabels(labels)
    setDialogState({})
  }

  return (
    <div style={{ width: '100%', margin: "10px" }} id="task-rhythm">
      <Backdrop open={isBackdropOpen} />
      <Autocomplete
        multiple
        freeSolo
        disableCloseOnSelect
        value={currentRecordLabels}
        id="tags-outlined"
        onChange={handleChange}
        options={availableRecordLabels}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => option.is_header}


        getOptionSelected={(option, value) => {
          return option.name === value.name
        }}
        groupBy={(option) => option.is_header && option.name}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        renderOption={(props, option, { selected }) =>
          !option.is_header && <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              {option.name}
              <FiberManualRecordIcon
                style={{ color: getLabelColorByCategory(option.category), }}
              />

            </div>
          </li>
        }
        renderInput={(params) => <TextField
          {...params}
          variant="outlined"
          label="Rhythm Labels"
          placeholder="Rhythm Labels"
        />}
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              icon={
                <FiberManualRecordIcon
                  style={{ color: getLabelColorByCategory(option.category) }}
                />
              }
              key={option.name}
              label={option.name}
              deleteIcon={<ClearIcon style={{ color: '#000' }} />}
              className={classes.chip}
            />
          ))
        }}
        
      // disableCloseOnSelect
      // multiple
      // onChange={handleChange}
      // // freeSolo
      // id="tags-outlined"
      // options={availableRecordLabels}
      // getOptionLabel={(option) => option.name}
      // isOptionEqualToValue={(option, value) => option.name === value.name}
      // getOptionDisabled={(option) => option.is_header}
      // getOptionSelected={(option, value) => option.name === value.name}

      // renderOption={(props, option, { selected }) => {
      //   return (
      //     <Box className={classes.listItem}>
      //       <Box>
      //         {!option.is_header && (
      //           <Checkbox
      //             icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
      //             checkedIcon={<CheckBoxIcon fontSize="small" />}
      //             style={{ marginRight: 8 }}
      //             checked={selected}
      //           />
      //         )}
      //         {option.name}
      //       </Box>
      //       {!option.is_header && (
      //         <FiberManualRecordIcon
      //           style={{ color: getLabelColorByCategory(option.category) }}
      //         />
      //       )}
      //     </Box>
      //   )
      // }}
      // renderInput={(params) => <TextField
      //       {...params}
      //       variant="outlined"
      //       label="Record Labels"
      //       placeholder="Record Labels"
      //     />}
      // renderTags={(value, getTagProps) => {
      //   return value.map((option, index) => (
      //     <Chip
      //       {...getTagProps({ index })}
      //       icon={
      //         <FiberManualRecordIcon
      //           style={{ color: getLabelColorByCategory(option.category) }}
      //         />
      //       }
      //       key={option.name}
      //       label={option.name}
      //       deleteIcon={<ClearIcon style={{ color: '#000' }} />}
      //       className={classes.chip}
      //     />
      //   ))
      // }}
      />

    <Snackbar
      open={snackbar}
      autoHideDuration={6000}
      onClose={() => setSnackBar(false)}
    >
      <Alert onClose={() => setSnackBar(false)} severity="error">
        {snackbar &&
          snackbar
            .split('')
            .map((letter, i) => (i ? letter : letter.toUpperCase()))
            .join('')}
      </Alert>
    </Snackbar>
      <UniversalDialog
        bigText="Are you sure?"
        icon={{
          icon: ErrorOutlineOutlinedIcon,
          color: '#EF6C00',
        }}
        buttons={[
          {
            text: 'Cancel',
            props: {
              onClick: () => setDialogState({}),
              variant: 'outlined',
              color: 'primary',
            },
          },
          {
            text: 'Add label',
            props: {
              onClick: handleAddLabelFromModal,
              variant: 'contained',
              color: 'primary',
            },
          },
        ]}
        {...dialogState}
      />
    </div>
  );
}

export default MultipleSelect
