import React from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import makeStyles from '@mui/styles/makeStyles';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'

import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 10,
    backgroundColor: '#F7F8FA',
    marginTop: theme.spacing(),
    color: '#000',
    borderRadius: 4,
  },
  wrapperDisabled: {
    opacity: 0.6,
  },
  icon: {
    marginRight: theme.spacing(),
  },
}))

function SanityLabel({ children, isError = false }) {
  const classes = useStyles()
  return (
    <Box className={clsx(classes.wrapper, !isError && classes.wrapperDisabled)}>
      <Grid container alignItems="center" ga>
        {isError ? (
          <CancelOutlinedIcon color="secondary" className={classes.icon} 
          style={{ color: '#F44336' }}/>
        ) : (
          <CheckCircleOutlineOutlinedIcon
            style={{ color: '#24A52A' }}
            className={classes.icon}
          />
        )}
        {children}
      </Grid>
    </Box>
  )
}

export default SanityLabel
