import React, { useState, useEffect, useRef, useMemo } from 'react'

import { Box, Paper, Grid, Button, Typography, Input, TextField } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import FlipIcon from '@mui/icons-material/Flip'
import PVCIcon from "../../assets/svgs/pvc.svg"
import PACIcon from "../../assets/svgs/pac.svg"


import clsx from 'clsx'

const toolbarHeight = 50

const toolStyles = makeStyles((theme) => ({
  caliper: { transform: 'rotate(90deg)' },
  vLabelTool: { transform: 'rotate(90deg)' },
  aLabelTool: { transform: 'rotate(90deg)' },
  deleteLabel: { transform: 'rotate(90deg)' },
  gridWrapper: {
    minHeight: toolbarHeight,
  },
  container: {
    marginTop: 0,
    minHeight: toolbarHeight,
    borderRadius: 0,
    boxShadow: '0px 1px 4px 0px #00000014',
    borderTop: '1px solid rgba(0, 0, 0, .12)',
  },
  toolLabelType: {
  },

  toolLabelTypeSecond: {
    fontSize: 14,
    textAlign: 'left',
    marginLeft: "10px"
  },
  toolbox: {
    flexWrap: 'nowrap',
  },
  recordLabelMenu: {
    margin: theme.spacing(),
    color: '#FFFFFF',
    backgroundColor: '#4caf50',
    '&:hover': { backgroundColor: '#357a38' },
  },
  tool: {
    marginRight: theme.spacing(),
    marginLeft: theme.spacing(),
    height: "100%"
  },
  toolName: {
    marginLeft: theme.spacing(),
    letterSpacing: 0,
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 500,
  },
  caliperOutput: {
    fontSize: 12,
    opacity: 0.7,
  },
  time: {
    borderRight: '1px solid rgb(0, 0, 0, 0.12)',
  },
  labelQuantity: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  borderLeft: {
    borderLeft: '1px solid rgb(0, 0, 0, 0.12)',
  },
  lightColor: {
    color: '#6c748f',
    marginLeft: 3,
  },
}))

function Tool({id, color, disableRipple, onClick, icon, title }) {
  const classes = toolStyles()

  return (
    <Button
      className={classes.tool}
      color={color}
      disableRipple={disableRipple}
      onClick={onClick}
      id={id}
    >
      {icon}
      <Typography className={classes.toolName}>{title}</Typography>
    </Button>
  )
}

function AnnotationToolbar(props) {
  const classes = toolStyles()
  const {
    activeTool,
    setActiveTool,
    currentTime,
    setActiveLabel,
    caliperState,
    data,
    setData,
    tools,
    PVCCount,
    PACCount,
    setPVCCount,
    setPACCount,
    invertECG,
    isFile
  } = props

  const wrapperRef = useRef(null)

  const [isSticky, setIsSticky] = useState(false)
  const [wrapperStyle, setWrapperStyle] = useState({})
  const [containerStyle, setContainerStyle] = useState({})

  const caliperVert = activeTool === 1

  let caliperDiff = null

  if (caliperState.current) {
    if (caliperVert) {
      caliperDiff = caliperState.current.yMax - caliperState.current.yMin
    } else {
      caliperDiff = caliperState.current.xMax - caliperState.current.xMin
    }
  }

  const checkForSticky = () => {
    if (wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect()
      const shouldBeSticky = rect.y <= 0
      setIsSticky(shouldBeSticky)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', checkForSticky)

    return () => {
      window.removeEventListener('scroll', checkForSticky)
    }
  }, [])

  useEffect(() => {
    const newWrapperStyle = isSticky ? { height: toolbarHeight } : {}
    const newContainerStyle = isSticky
      ? {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1000,
      }
      : {}

    setWrapperStyle(newWrapperStyle)
    setContainerStyle(newContainerStyle)
  }, [isSticky])

  const calipherValue = caliperVert
    ? (caliperDiff / 100).toFixed(2)
    : parseInt(caliperDiff * 1000)


  return (
    <Box style={wrapperStyle} ref={wrapperRef}>
      <Paper className={classes.container} style={containerStyle}>
        <Grid container className={classes.gridWrapper} wrap="nowrap">
          <Grid
            container
            item
            xs={4}
            style={{ maxWidth: 500 }}
            align="center"
            alignItems="center"
            justifyContent="center"
            wrap="nowrap"
            className={classes.time}
          >
            <Grid
              item
              xs
              style={{ maxWidth: 120 }}
              container
              direction="column"
            >
              <Grid item>
                {useMemo(
                  () => (<Typography variant="h6" className={classes.toolLabelType}>
                    {currentTime.toFixed(2)} s
                  </Typography>
                  ),
                  [currentTime]
                )}

              </Grid>
              {caliperState.current && (
                <Grid item>
                  <Typography className={classes.caliperOutput} align="center">
                    Caliper: {calipherValue} {caliperVert ? 'mV' : 'ms'}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              item
              style={{ maxWidth: 500, height: '100%' }}
              xs
              justifyContent="space-around"
            >
              <Grid
                item
                xs
                container
                wrap="nowrap"
                gap={1}
                className={clsx(classes.labelQuantity, classes.borderLeft)}
              >
                <Typography
                  variant="h6"
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  {`PVC: `}
                  {isFile ? 
                  <TextField
                  value={PVCCount} 
                  onChange={e => setPVCCount(Number(e.target.value))}
                    size="small"
                    type="number"
                    InputProps={{ inputProps: { min: 1 } }}
                    style={{ width: "100px" }} 
                    id="pvc-count" 
                    variant="outlined" />
                    : <span style={{ color: "gray" }}>{PVCCount}</span>}
                </Typography>
              </Grid>
              <Grid
                item
                xs
                gap={1}
                container
                wrap="nowrap"
                className={classes.labelQuantity}
              >
                <Typography
                  variant="h6"
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  {`PAC: `}
                  {isFile ? <TextField
                  value={PACCount} 
                  onChange={e => setPACCount(Number(e.target.value))}
                    InputProps={{ inputProps: { min: 1 } }}
                    type="number" size="small" style={{ width: "100px" }} id="pac-count" variant="outlined" />
                    : <span style={{ color: "gray" }}>{PACCount}</span>}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {!isFile && <Grid container item xs className={classes.toolbox} >
            <div id="task-toolbar-tour">
            {tools.map((tool, idx) => (
              <Tool
                id={tool.id}
                key={idx}
                title={tool.title}
                color={activeTool === idx ? 'primary' : 'secondary'}
                disableRipple={activeTool === idx}
                onClick={() => {
                  setActiveTool(idx)
                  setActiveLabel(tool.labelMapping)
                }}
                icon={
                  tool.wrapper
                    ? tool.wrapper({
                      children: React.createElement(tool.icon, {
                        className: classes[tool.name],
                      }),
                    })
                    : React.createElement(tool.icon, {
                      className: classes[tool.name],
                    })
                }
              />
            ))}</div>
            <Tool
              color="secondary"
              onClick={invertECG}
              icon={<FlipIcon style={{ transform: 'rotate(90deg)' }} />}
              title="Invert ECG"
            />
          </Grid>}
        </Grid>
      </Paper>
    </Box>
  );
}

export default AnnotationToolbar
