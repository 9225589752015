import { useState, useEffect, useRef } from 'react'

function useResizeObserver(elRef) {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const observer = useRef(
    new ResizeObserver((entries) => {
      // Only care about the first element, we expect one element ot be watched
      const { height, width } = entries[0].contentRect
      setHeight(height)
      setWidth(width)
    })
  )

  useEffect(() => {
    if (elRef.current) {
      observer.current.observe(elRef.current)
    }

  }, [elRef, observer])

  return [width, height]
}

export default useResizeObserver
