import { createSlice } from '@reduxjs/toolkit'
import * as endpoints from '../../common/endpoints'
import API from '../../common/api'
import moment from 'moment'

export const ecgSlice = createSlice({
  name: 'ecg',
  initialState: {
    ecg: {},
  },
  reducers: {
    setEcg: (state, action) => {
      state.ecg = { ...action.payload }
    },
    clearEcg: (state) => {
      state.ecg = {}
    },
  },
})

export const { setEcg, clearEcg } = ecgSlice.actions

export const selectEcg = (state) => state.ecg.ecg

export const getEcg = async (id) => {
  const store = await import('../store')
  return new Promise((resolve, reject) => {
    API.get(endpoints.GETECG(id))
      .then((data) => {
        data = data.data.data
        localStorage.getItem(data.ecg_id) || localStorage.setItem(data.ecg_id, moment.utc().format())
        store.default.dispatch(setEcg(data))
        resolve(data)
      })
      .catch((e) => {
        console.error(e)
        reject(e)
      })
  })
}

export default ecgSlice.reducer
