import React, {  useContext, useEffect } from 'react'

import {
  Grid,
  Card,
  CardContent,
  CssBaseline,
} from '@mui/material';
import SectionWrapper from '../../components/SectionWrapper'

import TaskTable from '../../components/TaskTable';
import TableHeader from '../../components/TableHeader';
import { TasksContext } from '../../contexts/Tasks.context';



export function Practice() {
  const { remainingPracticeTasksNumber, practiceTasks, assignPracticeTask, getPracticeTasks } = useContext(TasksContext)

  useEffect(() => {
    getPracticeTasks()
  }, [])

  return (
    <div>
      <CssBaseline />
      <SectionWrapper header={<TableHeader counter={remainingPracticeTasksNumber} title="Practice Mode" getNewTaskHandler={assignPracticeTask} />}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} align="center">
            <Card>
              <CardContent sx={{padding:"0"}}>
                <TaskTable {...{ taskData : practiceTasks, mode: 'practice' }} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </SectionWrapper>
    </div>
  );
}
