import React from 'react'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    width: (props) => props.size,
    height: (props) => props.size,
    margin: (props) => props.margin,
  },
  svg: {
    height: '100%',
    width: '100%',
  },
})

function Logo(props) {
  const classes = useStyles(props)
  return (
    <div className={classes.root}>
      <svg
        style={props.onClick && { cursor: 'pointer' }}
        onClick={props.onClick ? props.onClick : () => {}}
        className={classes.svg}
        width="46"
        height="46"
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="46" height="46" rx="6" fill="#F3F6FA" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.5628 0C38.4098 3.98001 39.4385 8.75209 39.4385 14.2012C39.4385 24.1444 36.0098 31.8458 30.4183 36.5668L37.6504 46H40C43.3137 46 46 43.3137 46 40V6C46 2.68629 43.3137 0 40 0H36.5628ZM27.4239 0C29.904 3.39667 31.3678 8.16684 31.3678 14.2012C31.3678 21.5861 29.1787 27.0984 25.5918 30.4743L20.396 23.696H12.3781L20.0531 33.7711C18.5234 34.2722 16.8618 34.5096 15.1211 34.5096C8.03528 34.5096 2.32442 30.3695 0 22.5892V6C0 2.68629 2.68629 0 6 0H27.4239ZM0 36.7544V40C0 43.3137 2.68629 46 6 46H29.3158L24.7478 40.0746C21.8466 41.3143 18.6025 41.9472 15.1211 41.9472C9.27671 41.9472 4.07752 40.1261 0 36.7544Z"
          fill="#0F56B3"
        />
      </svg>
    </div>
  )
}

export default Logo
