
import { Chip } from "@mui/material";
import { styled } from '@mui/system';

export const TableTag = styled(Chip)(({ theme, customColor }) => ({

    borderRadius: "4px",
    background: customColor ? `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${customColor}; ` : 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4760C2',
    color: customColor  || theme.palette.primary,
    border: 'none',

}))