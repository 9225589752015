import React from 'react'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: '#f7f8fa',
    padding: "2rem",
    minHeight:"85vh"
  },
  header: {
    paddingBottom: "1rem",
  },
  childrenWrapper: {
    paddingBottom: theme.spacing() * 3,
  },
}))

function SectionWrapper(props) {
  const classes = useStyles()
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.header}>{props.header}</Box>
      <Box className={classes.childrenWrapper}>{props.children}</Box>
    </Box>
  )
}

export default SectionWrapper
