import { toast, Slide } from "react-toastify";

export const TASK_STATUS = {
  0: 'Unassigned',
  1: 'Pending',
  2: 'In Progress',
  3: 'Completed',
}

export const TASK_TYPE = {
  'apple_watch': 'Apple Watch',
  'media_file': 'Media File',
}

export const AUDIT_STATUS = {
  0: 'Pending',
  1: 'Done',
}


export const INTERVAL_MAX_VALUE ={
  'p' : 121,
  'qrs' : 121,
  'pr' : 201,
  'qtc': 470
}

export const INTERVAL_MIN_VALUE ={
  'p' : 79,
  'qrs' : 79,
  'pr' : 119,
  'qtc': 349
}


export const SESSION_COOKIE = 'SESSION'

// number of coordinates per 10s
export const splitFrequency = 5121

export const TOASTR_OPTIONS = {
  position: toast.POSITION.BOTTOM_CENTER,
  transition: Slide,
  theme: "colored"
};
