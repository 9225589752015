import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

export const reviewErrorsSlice = createSlice({
  name: 'reviewErrors',
  initialState: {
    reviewErrors: [],
  },
  reducers: {
    setReviewErrors: (state, action) => {
      const newState = action.payload.map((error) => ({
        text: error,
        id: uuidv4(),
      }))
      state.reviewErrors = newState
    },
    removeReviewError: (state, action) => {
      const newState = state.filter((error) => error.id !== action.payload)
      state.reviewErrors = newState
    },
  },
})

export const { setReviewErrors, removeReviewError } = reviewErrorsSlice.actions

export const selectReviewErrors = (state) => state.reviewErrors.reviewErrors

export default reviewErrorsSlice.reducer
