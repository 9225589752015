import { InputAdornment, Stack, TextField } from '@mui/material'
import React from 'react'
import { useContext } from 'react'
import { TaskContext } from '../../../contexts/Task.context'

const PQRSTFields = ({ register }) => {
    const { task } = useContext(TaskContext)
    return (
        <Stack spacing={3} direction="row" m={2}>
            <TextField
                label="HR"
                type="number"
                size="small"
                disabled
                style={{ width: "80px" }}
            />
            <TextField
                label="P"
                size="small"
                type="number"
                {...register("p")}
                style={{ width: "100px" }}
                endAdornment={<InputAdornment position="end">ms</InputAdornment>}
                InputProps={{
                    endAdornment: (
                      <InputAdornment>ms</InputAdornment>
                    ),
                  }}
            />
            <TextField
                label="PR"
                size="small"
                type="number"
                {...register("pr")}
                style={{ width: "100px" }}
                endAdornment={<InputAdornment position="end">ms</InputAdornment>}
                InputProps={{
                    endAdornment: (
                      <InputAdornment>ms</InputAdornment>
                    ),
                  }}
            />
            <TextField
                label="QRS"
                size="small"
                type="number"
                {...register("qrs")}
                style={{ width: "100px" }}
                endAdornment={<InputAdornment position="end">ms</InputAdornment>}
                InputProps={{
                    endAdornment: (
                      <InputAdornment>ms</InputAdornment>
                    ),
                  }}
            />
            <TextField
                label="QT"
                size="small"
                type="number"
                style={{ width: "100px" }}
                endAdornment={<InputAdornment position="end">ms</InputAdornment>}
                InputProps={{
                    endAdornment: (
                      <InputAdornment>ms</InputAdornment>
                    ),
                  }}
                value={12}
            />
            <TextField
                label="QTC"
                size="small"
                type="number"
                style={{ width: "80px" }}
                value={12}
                disabled
            />
        </Stack>
    )
}

export default PQRSTFields
