import React from 'react'

import BackdropMUI from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import makeStyles from '@mui/styles/makeStyles';

import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 10000,
  },
  alignToParent: {
    position: 'absolute',
    zIndex: 1,
  },
}))

function Backdrop({ alignToParent = false, ...rest }) {
  const classes = useStyles()
  return (
    <BackdropMUI
      {...rest}
      className={clsx(classes.backdrop, alignToParent && classes.alignToParent)}
    >
      <CircularProgress color="inherit" />
    </BackdropMUI>
  )
}

export default Backdrop
