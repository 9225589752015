import { Fab, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Search as SearchIcon } from '@mui/icons-material';

import AddIcon from '@mui/icons-material/Add';
import { useContext } from 'react';
import { AdminContext } from '../../contexts/Admin.context';
import { TableTag } from '../../components/TaskTable/TaskTable.styled';
import EditIcon from '@mui/icons-material/Edit';
import EditProfileDialog from '../../components/EditProfileDialog';
import { addTechnician, getTechnician, saveTechnician } from '../../services/admin';

// table settings
const initialRowsPerPage = 50
const initialPage = 0

const TechniciansTab = () => {
    const { technicianData, getTechnicians } = useContext(AdminContext)
    const [isEditProfileDialogOpen, setIsEditProfileDialogOpen] = useState(false)
    const [technician, setTechnician] = useState(null)
    const [activeTechnician, setActiveTechnician] = useState(null)
    const [isAdding, setIsAdding] = useState(false)


    const [page, setPage] = useState(initialPage)
    const [search, setSearch] = useState(null)
    const [searchBy, setSearchBy] = useState('username')

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        getTechnicians(page + 1, initialRowsPerPage, search, searchBy)
    }, [page, search, searchBy])

    useEffect(() => {
        if (activeTechnician) {
            getTechnician(activeTechnician).then(res => setTechnician(res))
        } else {
            setTechnician({})
        }
    }, [activeTechnician])


    const handleSave = (data) => {
        isAdding ? addTechnician(data).then(res => console.log(res)).finally(() => setIsEditProfileDialogOpen(false)) : saveTechnician(data, activeTechnician).then(res => console.log(res)).finally(() => setIsEditProfileDialogOpen(false))
    }

    const  getColor =(role) =>{
        if(role === 'admin'){
          return  '#DA100B'
        }else if(role === 'auditor'){
            return  '#4CAF50'

        }else if(role === 'technician' || role === 'junior_technician' ){
            return  '#3F51B5'

        }else {
            return  '#ED6C02'

        }
    }
    return (

        <>
            <TextField
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                size="small"
                sx={{ mt: "15px", flex: 1, backgroundColor: "white", width: "100%" }}
                variant="outlined"
                placeholder={`Seach by ${searchBy}...`}
                InputProps={{
                    style: { paddingLeft: "0px" },
                    startAdornment: <>

                            <Select
                                displayEmpty
                                variant="standard"
                                value={searchBy}
                                sx={{background:"rgba(0, 0, 0, 0.04)",height:"44px", width: "110px",
                                textAlign:"center",
                                paddingTop:"5px"}}
                                onChange={(e) => setSearchBy(e.target.value)}
                                disableUnderline
                                inputProps={{
                                    underline: {
                                        "&&&:before": {
                                            borderBottom: "none"
                                        },
                                        "&&:after": {
                                            borderBottom: "none"
                                        }
                                    }
                                }}

                            >
                                <MenuItem value='username'>Name</MenuItem>
                                <MenuItem value='email'>Email</MenuItem>
                                <MenuItem value='phone'>Phone</MenuItem>
                            </Select>
                        <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton></>,
                }}
            />

            <TableContainer style={{ marginTop: "20px", maxHeight: "60vh" }} component={Paper}>
                <Table size="small" stickyHeader sx={{ minWidth: 750 }} aria-label="simple table">
                    <TableHead sx={{height:"56px"}}>
                        <TableRow>
                            <TableCell>Username</TableCell>
                            <TableCell >Role</TableCell>
                            <TableCell >Phone Number</TableCell>
                            <TableCell >Email</TableCell>
                            <TableCell >Location</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {technicianData.technicians?.map((row) => (
                            <TableRow
                                key={row.username}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.username}
                                </TableCell>
                                <TableCell ><TableTag customColor={getColor(row.role)} label={row.role.slice(0,1).toUpperCase()+row.role.slice(1)} /> </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.phone}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.email}
                                </TableCell>
                                <TableCell >{row.country ? row.country + ", " + row.city : ""}</TableCell>
                                <TableCell align="right" >
                                    <Tooltip title="Edit profile">
                                        <IconButton
                                            onClick={() => {
                                                setIsEditProfileDialogOpen(true)
                                                setActiveTechnician(row.id)
                                            }}
                                            size="large">
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip></TableCell>

                            </TableRow>))}
                    </TableBody>

                </Table>
                <TableFooter sx={{ float: "right", position: "sticky", bottom: "0" }}>

                    <Fab onClick={() => {
                        setActiveTechnician(null)
                        setIsEditProfileDialogOpen(true)
                        setIsAdding(true)
                    }} variant="extended" size="large" color="primary" aria-label="add" sx={{ m: 2 }}>
                        <AddIcon sx={{ mr: 1 }} />
                        NEW TECHNICIAN
                    </Fab>
                </TableFooter>
            </TableContainer>

            <EditProfileDialog
                admin
                add={isAdding}
                open={isEditProfileDialogOpen}
                onClose={() => {
                    setIsEditProfileDialogOpen(false)
                    setIsAdding(false)
                }}
                onSave={handleSave}
                userInfo={technician}
            />

            <TablePagination
                component="div"
                count={technicianData.meta?.total_count || 0}
                rowsPerPage={initialRowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
            />

        </>
    )
}

export default TechniciansTab
